module.exports = {
    personal_data:'profile',
    avatar:'avatar',
    nickname:'nickname',
    passport:'passport number',
    birth_date:'birth date',
    not_set:'Not set yet',
    please_input:'Please input',
    my_email:'Email',
    my_phone:'my phone',
    not_bind_phone:'The phone has not been bound yet',
    not_bind_email:'The mailbox has not been bound yet',
    change:'replace',
    bind:'bind',
    click_upload:'Click to upload',
    upload_tip:'Only jpg/png files can be uploaded, and no more than 12MB',
    select_date:'Select date',
    alter_nickname:'Modify nickname',
    cancel:'Cancel',
    confirm:'OK',
    alter_passport:'Modify passport',
    select_y_t_d:'Select year month day',
    please_input_nickname:'Please enter a nickname!',
    please_input_passport:'Please enter your passport!',
    nonsupport:'Special characters are not supported!',
    alter_success:'Modification succeeded!!',
    avatar_upload_success:'The avatar was uploaded successfully!',
    img_err1:'The image can only be in JPG or PNG format!',
    img_err2:'Image size cannot exceed 12MB!'
}
