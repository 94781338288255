module.exports={
    go_back:'返回前页',
    member_center:'会员中心',
    user_activation_code:'使用激活码',
    member_right:'会员权益',
    member_title:'极致量化体验 尽享财富盛宴',
    member_title_sub:'开通',
    member_title_sub2:'尊享多重服务',
    renew_eight_discount:'续费限时8折',
    upgrade:'升级为',
    please_input_member_activation_code:'请输入会员激活码',
    conversion:'兑换',
    confirm_order_form:'确认订单',
    member_privilege:'会员特权/年',
    order_money:'订单金额',
    property_money:'资产金额',
    balance_lack:'仅支持资产余额支付,当前余额不足,请先',
    top_up:'充值',
    support_type:'仅支持资产余额支付',
    please_input_email_auth_code:'请输入邮箱验证码',
    please_input_phone_auth_code:'请输入手机验证码',
    send_auth_code:'发送验证码',
    regain:'后重新获取',
    confirm_open:'确认开通',
    property_top_up:'资产充值',
    main_net:'主网',
    wallet_address:'钱包地址（此地址只可接收USDT）',
    important_tip:'重要提示',
    least_top_up_money:'最少充值金额为',
    top_up_tip1:'请仔细确认地址，充错无法找回',
    top_up_tip2:'大约3~5分钟到账',
    user_already_top_up:'我已充值',
    conversion_success:'兑换成功,您已成为',
    perpetual:'永久',
    i_see:'我知道了',
    please_input_activation_auth_code:'请输入激活码',
    please_input_auth_code:'请输入验证码',
    perpetual_valid:'永久有效',
    expire:'到期',
    not_yet_open:'尚未开通',
    balance_lack_tip:'余额不足 !',
    sent_phone_auth_code:'验证码已发送到您的手机 !',
    sent_email_auth_code:'验证码已发送到您的手机 !',
    copy_success:'复制成功 !',
    get_balance_error:'获取资产充值信息失败 !',
    demotion_buy_warn:'您当前已经是SVIP,不可降级购买VIP !',
    property_money_new: '资金账户余额',
    balance_lack_new:'仅支持资金账户余额支付,余额不足,请先',
    support_type_new:'仅支持资金账户余额支付',

    //会员
    my_member:'我的会员',
}
