module.exports ={
    account_login: '賬號登錄',
    login_tips: '歡迎回來！使用您的電子郵件或者電話號碼登錄',
    account: '賬號',
    password: '密碼',
    login: '登錄',
    save_pwd: '保存密碼',
    forget_pwd: '忘記密碼',
    register: '立即註冊',
    input_phone_email: '請輸入郵箱或者手機號',
    input_pwd: '請輸入密碼',
    input_phone: '請輸入手機號',
    input_email: '請輸入郵箱地址',
    input_code: '請輸入驗證碼',
    sure_login_info: '請確認登錄信息是否正確!',
    login_success: '登錄成功',
    open_incognito_mode: '您已開啓無痕模式，期間您的賬號、密碼將不會被存儲',

    <!-- 注册 -->
    create_felton_account: '創建UPlus賬號',
    email: '郵箱',
    mobile_phone: '手機號',
    input_email_number: '請輸入郵箱',
    send_code: '發送驗證碼',
    resend_code: '後重新獲取',
    input_invitation_code: '請輸入邀請碼',
    I_have_read_and_agree: '我已經閱讀並同意',
    terms_of_service_of_felton: 'UPlus的用戶協議',
    register_save: '註冊',
    registered: '已有賬號？',
    input_area_code: '請輸入區號',
    input_check_code: '請輸入校驗碼',
    read_terms_of_service: '請閱讀服務條款',
    confirm_whether_registrationinfo_correct: '請確認註冊信息是否正確！',
    regist_success: '註冊成功',
    confirm_whether_phone_format_area_correct: '請確認手機號格式或區號是否正確！',
    code_sended_phone: '驗證碼已發送到您的手機！',
    input_right_email: '請輸入正確的郵箱',
    code_sended_email: '驗證碼已發送到您的郵箱！',
    recommender_ID: '推薦人ID',
    optional: '選填',
    authCode: '驗證碼',

    <!-- 忘记密码页面 -->
    home_page: '首頁',
    back_homepage: '返回首頁',
    reset_password: '重置密碼',
    input_new_pwd: '請輸入新密碼',
    next_step: '重置',
    confirm_entered_info_is_correct: '請確認輸入信息是否正確！',
    password_setting_succeeded: '密碼設置成功，請登錄！',
    mobile_already_exists: '手機號已存在',
    email_already_exists: '郵箱已存在',

}
