import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state={
  isEmailRegister:true,
  isBindEmail:false,
  isShowTip:true,
  remindDialog:true,
  sidebarType:'',
//  判断是否手动修改过补仓配置或者补仓回调 0 未修改   1修改过
  alterCoverConfig:0,
  alterCoverCallback:0,
  coverConfig:[],   //用于保存 修改过的补仓配置
  coverCallback:[],   //用于保存 修改过的补仓回调

  showFeedBack: false,

//  用于判断是添加还是修改并保存数据
  addAlterMartin:1
}
export default new Vuex.Store({
  state,
  mutations: {
    changeSideType(state,type){
      state.sidebarType=type
    },

    changeAlterCoverConfigOrCallback(state,value){
      state.alterCoverConfig=value
      state.alterCoverCallback=value
    },

    changeAlterCoverConfig(state,value){
      state.alterCoverConfig=value
    },
    changeAlterCoverCallback(state,value){
      state.alterCoverCallback=value
    },

    changeCoverConfig(state,value){
      state.coverConfig=JSON.parse(JSON.stringify(value))
    },
    changeCoverCallback(state,value){
      state.coverCallback=JSON.parse(JSON.stringify(value))
    },

    addAlterMartin(state,value){
      state.addAlterMartin=JSON.parse(JSON.stringify(value))
    },


  },
  getters:{
    getSidebarType(state){
      return state.sidebarType
    }
  },
  actions: {
  },
  modules: {
  }
})
