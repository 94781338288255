module.exports = {
    robot_title_earn:'Robot revenue (only statistics after September 1, 2021）',
    today_earn:'Today\'s earnings:',
    add_app_earn:'Accumulated earnings:',
    add_up_rate:'Cumulative rate of return:',
    month_average_rate:'monthly average rate of return:',
    total_earn_rank :'Monthly revenue ranking:',
    no_listed:'Not on the list',
    robot_earn:'Robot Earning',
    total_earn_rank_title:'Monthly income ranking (USDT) (My Rank:',
    else_list:'Other list',
    earn_statistics:'Earning Statistics',
    everyday_earn_tip:'Data settlement is performed at 0:00-0:10 every day, and data is updated at 0:10',
    today:'Today',
    yesterday:'Yesterday',
    week:'Week',
    month:'Month',
    year:'Year',
    all:'All',
    goods_type:'Policy type',
    transaction_order_num:'Number of transaction orders',
    earn_money:'Earning Amount',
    earn_query:'Earning Query',
    select_month:'Select month',
    month_earn:'Monthly Earnings',
    month_earn_rate:'Monthly rate of return',
    month_earn_statistics:'Monthly Earnings Statistics',
    earn:'earnings',
    day_earn_rate:'Daily rate of return',
    today_earning_rate: 'Today\'s rate of return',
    income_month: 'This month\'s income',
    current_month_yield: 'This month\'s yield',
    sure: 'Sure',
    hide_no_profit: 'hide 0 profit strategy',
    profit_detail: 'profit details',
    share_profit: 'share the profit',
    robot_day_profit: 'Robot profit today',
    robot_profit_total: 'Accumulated earnings',
    add_up_rate_total: 'Cumulative rate of return',
    date: 'date',
    transaction_order: 'Number of transaction',
    rate_total: 'profit rate',
    transaction_order_of_num: 'Number of transaction',
    details: 'Details',
    see: 'Details',
    data: 'data',
    daily_revenue_details: 'Daily revenue details',


//    ranking
    total_earn_rank_r:'Cumulative earnings ranking (USDT)',
    my_rank:'my rank',


//    share
    robot_total_earn :' robot cumulative revenue ',
    share_title :' Committed to building a secure and convenient strategic trading platform ',
    share_time :' share time ',
    download_img :' Download image '
}
