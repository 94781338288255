module.exports = {
    s_vip_member:'SVIP年度會員',
    vip_member:'VIP年度會員',
    vip_enjoy:'VIP會員尊享',
    big_rights:'大權益',
    member_title:'极致量化体验 尽享财富盛宴',
    member_title_sub1:'开通',
    enjoy:'尊享',
    //content
    content_title:'專享黃金三角套利交易',
    content_info:'平臺潛心研發的最優量化交易策略，特供SVIP用戶專享，加速開啓財富自由之門',

    high_reach:'高至',
    content_title2:'個交易對添加特權',
    content_info2_s:'用戶最高可添加8個全自動交易對12個半自動交易對，充分滿足不同用戶的交易需求',
    content_info2_v:'用戶最高可添加3個全自動交易對8個半自動交易對，可滿足用戶的基礎交易需求',

    content_title3:'專屬服務器保障',
    content_info3_s:'爲SVIP用戶特別搭建專屬服務器，交易信號稍縱即逝，專屬服務保障交易順暢運行',

    content_title4:'限定皮膚彰顯身份',
    content_info4_s:'即將推出SVIP會員版定製皮膚，滿足個性化需求，彰顯尊貴身份',

    content_title5:'定製週報追蹤數據',
    content_info5_s_min:'即將爲',
    content_info5_s:'用戶推出定製週報服務，每週爲用戶推送交易數據及操作建議',

    content_title6:'金牌客服1對1服務',
    content_info6_s:'1對1服務，爲用戶答疑解惑，講授全自動量化投資相關知識',

    content_title7:'會員俱樂部',
    content_info7_s:'志趣相投的量化投資人聚集地，觀點交流、智慧碰撞的私享互動',

    content_title7_svip:'燃料費享8折優惠',
    content_info7_s_svip:'SVIP用戶專享燃料費8折優惠，不限策略，沒有減免上限',

    content_title8:'活動優先參與權',
    content_info8_s:'平臺不定期推出限定名額的優惠活動，級別越高越優先享有活動參與權',

    content_title9:'專屬會員福利',
    content_info9_s:'平臺不定期推出福利活動回饋用戶，根據用戶級別享有該級別的專屬福利',

//
    confirm_agreement:'確認開通即視爲同意《Felton會員服務協議》',
    year:'年',
    prime_price:'原價',
    eight_renew:'8折續費',
    // eight_renew:'續費',
    buy_it_now:'立即搶購',

    //我的会员单独
    user_member1:'用戶最高可添加',
    user_member2:'12個全自動交易對20個半自動交易對',
    user_member3:'8個全自動交易對12個半自動交易對',
    user_member4:'3個全自動交易對8個半自動交易對',
    user_member5:'充分滿足不同用戶的交易需求',
    user_member6:'可滿足用戶的基礎交易需求',
    After_trial_expires_tip: '試用到期恢復至1980',

}
