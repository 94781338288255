module.exports={
    rebate_title:'邀请好友来赚钱',
    rebate_title_sub:'好友变豪友，一起有钱花',
    user_grade:'我的等级:',
    user_superior:'我的上级:',
    set_superior:'设置上级',
    drive_one:'直推奖励 : ',
    drive_two:'团队奖励 : ',
    drive_two_1:'间推奖励 : ',
    user_invite_code:'我的邀请码:',
    spread_link:'推广链接: ',
    create_poster:'生成推广海报',
    capital_account:'资金账户',
    trading_account:'交易账户(燃料费)',
    // rebate_account:'返佣奖金',
    rebate_account:'奖金',
    withdraw:'提现',
    withdraw_of:'提现中',
    top_up:'充值',
    transfer:'划转',
    total_performance:'总业绩',
    until:'统计截止至 :',
    user_direct:'我的直推',
    common:'共',
    person:'人',
    invite_code:'邀请码',
    user:'用户',
    register_date:'注册时间',
    vip_level:'vip等级',
    award_date:'奖励时间',
    award_money:'奖励金额(USDT)',
    billing_profit:'开单利润(USDT)',
    write_date:'纪录时间',
    single_profit:'单笔利润（USDT)',
    property_change:'资产变更（USDT)',
    property_balance:'资产余额（USDT)',
    change_type:'变更类型',
    bonus_cut_in:'奖金划入',
    poster_title:'开启Felton，享受你的睡后收益！',
    spread_id:'推荐ID',
    download_poster:'下载推广海报',

    property_withdraw:'资产提现',
    withdraw_money:'提现金额',
    all:'全部',
    withdraw_address:'提现地址',
    withdraw_tip_info:'（币安邮箱地址）',
    TRC20_address:'TRC20地址',
    submit_apply:'提交申请',
    property_withdraw_confirm:'资产提现确认',
    email_auth_code:'邮箱验证码',
    send_auth_code:'发送验证码',
    regain:'后重新获取',
    confirm_withdraw:'确认提现',
    set:'设置',
    please_input_withdraw_money:'请输入提现金额',
    please_input_withdraw_address:'请输入提现地址',
    please_input_email_auth_code:'请输入邮箱验证码',
    copy_success:'复制成功 !',
    please_bind_email:'请先绑定邮箱 !',
    auth_code_send_email:'验证码已发送到您的邮箱 !',
    withdraw_money_unable_empty:'提现金额不能为空 !',
    point_two:'最多只能输入小数点后两位 !',
    withdraw_address_unable_empty:'提现地址不能为空 !',
    email_format_incorrectness:'邮箱格式不正确 !',
    withdrawal_address_incorrect:'提现地址不正确 !',
    withdraw_info_input_error:'提现信息输入错误 !',
    withdraw_apply_sent:'提现申请已发送',
    please_input_superior_invitation_auth_code:'请输入上级邀请码 !',
    set_success:'设置成功 !',
    download_img:'下载图片',

    recommend_count:'直推人数',
    total_performance1:'总业绩',
    member_type:'会员类型',
    robot_state:'机器人状态',
    robot_change_time:'机器人开/关时间',
    fuel_fee:'燃料费',
    reward_record_of_this_month: '本月奖励记录',
    ordinary_users: '普通用户',
    rule: '规则',

}
