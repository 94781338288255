import Vue from "vue";
import infiniteScroll from 'vue-infinite-scroll';
import VueCookies from 'vue-cookies'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

import {
    Button,
    Pagination,
    Table,
    TableColumn,
    Empty,
    Container,
    Header,
    Main,
    Aside,
    Footer,
    Menu,
    MenuItem,
    MenuItemGroup,
    Submenu,
    Drawer,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    MessageBox,
    Message,
    Card,
    Select,
    Option,
    DatePicker,
    Dialog,
    Form,
    FormItem,
    Input,
    Loading,
    Switch,
    Checkbox,
    Tabs,
    TabPane,
    Radio,
    Upload,
    Carousel,
    CarouselItem,
    Skeleton,
    SkeletonItem,
    Popover,
    Tooltip,
    Tree,
    RadioGroup,
    RadioButton,
    Slider,
    Breadcrumb,
    BreadcrumbItem,
    Divider,
    Image,
    Link,
    Collapse,
    CollapseItem,
    Progress

} from "element-ui";
// Dialog.props.lockScroll.default = false;
Vue.use(Button)
Vue.use(Pagination)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Empty)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Aside)
Vue.use(Footer)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(Drawer)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Card)
Vue.use(Select)
Vue.use(Option)
Vue.use(DatePicker)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Switch)
Vue.use(Checkbox)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Radio)
Vue.use(Upload)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Skeleton)
Vue.use(SkeletonItem)
Vue.use(Popover)
Vue.use(Tooltip)
Vue.use(Tree)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Slider)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Divider)
Vue.use(Image)
Vue.use(Link)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Progress)


Vue.use(Loading.directive);
Vue.use(VueCookies)
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$loading = Loading.service;

Vue.prototype.$message = function(msg){
    return Message({
        message:msg,
        duration:5000,
        showClose:true
    })
}
Vue.prototype.$message.success = function (msg) {
    return Message.success({
        message: msg,
        duration: 4000,
        showClose:true
    })
}
Vue.prototype.$message.warning = function (msg) {
    return Message.warning({
        message: msg,
        duration: 5000,
        showClose:true
    })
}
Vue.prototype.$message.error = function (msg) {
    return Message.error({
        message: msg,
        duration: 5000,
        showClose:true
    })
}
Vue.use(infiniteScroll)
Vue.component(CollapseTransition.name, CollapseTransition)


//vant
import {Popup,DatetimePicker,Toast} from "vant";
import * as Process from "process";
Vue.use(Popup)
Vue.use(DatetimePicker);
Vue.use(Toast)

