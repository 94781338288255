module.exports = {
    capital_transfer:'Fund transfer',
    from:'from',
    to:'To',
    balance:'balance',
    please_select:'Please select',
    transfer_money:'Please select',
    all:'All',
    confirm_transfer:'Confirm transfer',
    capital_account:'capital account',
    trading_account:'fuel account',
    // rebate_account:'Rebate bonus',
    rebate_account:'bonus',
    please_input_transfer_money:'Please enter the transfer amount!',
    restrict_input:'Please enter the transfer amount!',
    transfer_success:'transfer successful!',
    warn_not:'Cannot transfer to rebate bonus!',
    not_negative_numbers: 'transfer amount must be ≥ 0',
}
