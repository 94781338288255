module.exports = {
    select_trading_on:'Select strategy trading pair', 
    please_select_trading_on:'Please select a strategy pair !',
    please_select:'Please select',
    select_strategy_type:'Select strategy type',
    please_select_strategy_type:'Please select a strategy type!',
    param_detail:'parameter details',
    fold:'fold',
    unfold:'expand',
    not_advice:'If it will not be set, it is recommended to use the default parameters',
    recover_default:'Recover default parameters',
    full_order_money:'full order position',
    trendPeriod:'trend period',
    please_select_trendPeriod:'Please select the trend period!',
    firstOrderMoney:'First order amount',
    please_input_firstOrderMoney:'Please enter the first order amount!',
    please_input_firstOrderMoney_error:'The first order amount was entered incorrectly!',
    cycleIndex:'Number of cycles',
    please_input_cycleIndex:'Please enter the number of cycles',
    firstOrderTimes:'First single multiple',
    please_input_firstOrderTimes:'Please enter the first order multiple!',
    orderNumber:'Number of positions to cover',
    please_input_orderNumber:'Please enter the number of margin calls!',
    times:'times',
    stopProfitRatio:'Stop Profit Ratio',
    please_input_stopProfitRatio:'Please enter the take profit ratio!',
    stopProfitCallback:'Trailing take profit callback',
    please_input_stopProfitCallback:'Please enter the trailing take profit callback!',
    stopProfitCallback_tip:'Please set the take profit callback first and then close it!',
    stopProfitCallback_tip2:'The trailing take profit callback is not recommended to be larger than the take profit ratio',
    coverConfig:'covering configuration',
    coverCallback:'Tracking Cover Callback',
    fullOrderStopLoss:'full order stop loss',
    stopLossRatio:'Stop Loss Ratio(Based on final order price)',
    please_input_stopLossRatio:'Please enter the stop loss ratio!',
    full_order_money_exceed_limit:'The full order position exceeds the limit!',
    strategy_success1:'Generation strategy succeeded',
    strategy_success2:'Restart strategy succeeded',
    strategy_success3:'Modify strategy successfully',
    closed:'closed',
    have_set:'Have set',
    not_have_set:'Not set',
    close:'Close',
    open:'Open',
    limited:'limited',
    infinite:'limited',
    cancel:'Cancel',
    confirm:'Confirm',
    minute:'minute',
    alter_next_effect:'The next cycle will take effect after modification',
    alter_dialog_tip:'After modifying the <span style="font-weight: 600;color: red"> fill count </span>, the fill configuration and trailing fill callbacks will revert to the default parameters',
    least_deal:'The minimum trade amount is',
    //tip
    not_input_decimals:'Cannot enter decimals!',
    cover_times_not_greater:'The number of times to cover positions cannot be greater than',
    tip2:'The first single multiple must be greater than or equal to',
    tip3:'The input parameter cannot be',
    tip4:'The input parameter cannot be'
}