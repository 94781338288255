module.exports={
    title:'The contract quantification robot is safe, convenient and efficient, making transactions more secure.',
    ul_li1:'contact customer',
    ul_li2:'Help center',
    ul_li2_phone:'Help',
    ul_li3:'OKX registered address',
    ul_li4:'Terms of service',
    ul_li4_phone:'Service',
    download_app: 'Download App',
    send: 'Send'
}
