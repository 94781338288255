module.exports = {
    felton_quantitative_title: 'UPlus量化交易機器人',
    felton_quantitative_content1: '基於大數據和人工智慧的虛擬貨幣交易機器人',
    felton_quantitative_content2: '智慧構建讓您躺著賺錢',
    start_robot: '啟動機器人',
    product_description: '產品介紹',

    golden_triangle: '黃金三角交易',
    stable_income: '收益穩定',
    introduce: '介紹',
    golden_triangle_tip: '三個幣種迴圈交易,活躍度高',
    stability: '穩定性',
    rate_return: '收益率',
    capture_big_market: '捕捉大行情的能力',

    bb_transaction: 'BB交易',
    long_term_profit: '長期收益',
    bb_transaction_tip: '任意兩個幣種交易,適合橫盤屯幣',

    ub_transaction: 'UB交易',
    high_return: '高回報',
    ub_transaction_tip: '用USDT交易貨幣,獲得長期理財收益',

    grid_strategy: '網格策略',
    volatility_arbitrage: '震盪套利',
    grid_strategy_tip: '震盪行情自動低買高賣,穩定套利',

    martin_strategy: '馬丁策略',
    money_management: '倉位管理',
    martin_strategy_tip: '單邊加倍押注拉低成本,止盈回檔',

    contract_strategy: '對沖合約交易',
    stay_tuned_for: '收益率高',
    coming_soon: '合約多空對衝虧損，震蕩套利，超高收益',

    investment_advice: '產品優勢',
    reference_plan: '參攷建議',
    product_portfolio: '產品介紹',
    membership_level: '會員等級',
    for_people: '適合人群',
    features: '特點',
    monthly_return: '月收益率',
    turn_on: '創建',
    option_one: '選項一',
    option_one_introduce: '2個“黃金三角交易”\n+\n2個“BB”交易',
    option_one_for_people: '囤幣的比特幣信仰者',
    option_one_features: '多幣種交易,開單量大,收益長期穩定',
    option_two: '選項二',
    option_two_introduce: '1個“黃金三角交易”\n+\n5個“BB”交易',
    option_two_for_people: '囤幣的比特幣信仰者',
    option_two_features: '適合在平盤交易中獲利,不適合劇烈的市場波動',
    option_three: '選項三',
    option_three_introduce: '2個“UB交易”\n+\n1個“BB交易”',
    option_three_for_people: '獲得長期理財收益',
    option_three_features: '適合在市場劇烈波動時賺取收益,短期內可能有30%-50%的浮虧',
    no_suitable_solution: '沒有合適的解決方案,試試自定義',
    custom_solution: '解決方案？',

    product_advantages: '產品優勢',
    exclusive_strategy_model: '獨家策略模型',
    exclusive_strategy_model_tip: '智慧交易算灋和精細化倉位管理,强大的風控系統和靈活的點比特捕捉',
    fully_automatic_unattended: '全自動無人值守',
    fully_automatic_unattended_tip: '傻瓜式代理投資,降低時間成本,彌補信息不對稱,輕鬆享受睡後收益',
    financial_security: '資金安全保障',
    financial_security_tip: '支持API接入Binance、Okx等各大交易所,僅調用交易許可權,數位資產始終在自己的交易所帳戶中',
    regularly_update_policy: '定期更新策略',
    regularly_update_policy_tip: '强大的科技策略團隊,根據市場變化,定期調整參數、更新策略',

    opening_process: '開通流程',
    choose_exchange_connect: '選擇一個交易所並連接',
    recharge_fuel: '充值燃料費'

}
