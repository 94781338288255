module.exports={
    rebate_title:'Invite friends to make money',
    rebate_title_sub:'Friends become wealthy friends, and have money to spend together',
    user_grade:'My grade:',
    user_superior:'My superior:',
    set_superior:'Set the superior',
    drive_one:'Direct push reward: ',
    drive_two:'recommend reward: ',
    drive_two_1:'Indirect recommendation: ',
    user_invite_code:'My invitation code:',
    spread_link:'Promotion link: ',
    create_poster:'Generate promotion poster',
    capital_account:'capital account',
    trading_account:'trading account',
    // rebate_account:'Rebate bonus',
    rebate_account:'bonus',
    withdraw:'withdrawal',
    withdraw_of:'withdrawing',
    top_up:'Top up',
    transfer:'transfer',
    total_performance:'total performance',
    until:'Statistics until:',
    user_direct:'My direct push',
    common:'common',
    person:'person',
    invite_code:'invitation code',
    user:'user',
    register_date:'registration time',
    vip_level:'vip level',
    award_date:'Award time',
    award_money:'Amount of award (USDT)',
    billing_profit:'Billing Profit (USDT)',
    write_date:'Record time',
    single_profit:'Single profit (USDT)',
    property_change:'Asset change (USDT)',
    property_balance:'Asset balance (USDT)',
    change_type:'Change type',
    bonus_cut_in:'Bonus cut in',
    poster_title:'Turn on Felton and enjoy your after-sleep benefits!',
    spread_id:'Recommended ID',
    download_poster:'Download promotion poster',

    property_withdraw:'Asset withdrawal',
    withdraw_money:'withdrawal amount',
    all:'all',
    withdraw_address:'Withdrawal address',
    withdraw_tip_info:'(Binance email address)）',
    TRC20_address:'TRC20 Address',
    submit_apply:'Submit application',
    property_withdraw_confirm:'Asset withdrawal confirmation',
    email_auth_code:'Email verification code',
    send_auth_code:'Send verification code',
    regain:'reacquire after',
    confirm_withdraw:'Confirm withdrawal',
    set:'Set',
    please_input_withdraw_money:'Please enter the withdrawal amount',
    please_input_withdraw_address:'Please enter the withdrawal address',
    please_input_email_auth_code:'Please enter the email verification code',
    copy_success:'Copy successfully!',
    please_bind_email:'Please bind your email address first!',
    auth_code_send_email:'The verification code has been sent to your email!',
    withdraw_money_unable_empty:'The withdrawal amount cannot be empty!',
    point_two:'You can only enter at most two decimal places!!',
    withdraw_address_unable_empty:'The withdrawal address cannot be empty!',
    email_format_incorrectness:'The email format is incorrect!',
    withdrawal_address_incorrect: "The withdrawal address is incorrect",
    withdraw_info_input_error:'Withdrawal information input error!',
    withdraw_apply_sent:'The withdrawal application has been sent',
    please_input_superior_invitation_auth_code:'Please enter the superior invitation code!',
    set_success:'Set successfully!',
    download_img:'Download image',

    recommend_count:'direct push number',
    total_performance1:'total performance',
    member_type:'member\'s type',
    robot_state:'robot state',
    robot_change_time:'robot on / off time',
    fuel_fee:'fuel fee',
    reward_record_of_this_month: 'Reward record of this month',
    ordinary_users: 'Ordinary Users',
    rule: 'rule',
}
