const robot = require('./language/zh-tw/robot')
const user_center = require('./language/zh-tw/user_center')
const rebate = require("./language/zh-tw/rabate");
const member_right = require("./language/zh-tw/grant_member/member_right");
const member = require("./language/zh-tw/grant_member/member");
const martinComments = require("./language/zh-tw/martin/martinComments");
const detail_param=require('./language/zh-tw/martin/detail_param')
const transfer=require('./language/zh-tw/user/transfer')
const top_up=require('./language/zh-tw/top_up')
const property = require("./language/zh-tw/user/property");
const personal = require("./language/zh-tw/user/personal");
const center = require("./language/zh-tw/center");
const validate = require("./language/zh-tw/validate");
const footer = require("./language/zh-tw/footer");
const help = require("./language/zh/help");
const login = require("./language/zh-tw/login");
const alter = require("./language/zh-tw/alter")
const home = require("./language/zh-tw/home")
const navbar = require("./language/zh-tw/navbar")
const problem = require("./language/zh-tw/problem")
module.exports = {
    message: {
        login: '登录',
        Username: '用户名',
        Password: '密码',
        Captcha: '验证码',
        Language: '语言',
        zh: '中文',
        en: '英文',
        footer_title:'致力於打造安全、便捷的策略交易平台，帶來簡單、極致、高效的量化體驗。',
    },
    robot,
    user_center,
    rebate,
    member_right,
    member,
    martinComments,
    detail_param,
    transfer,
    top_up,
    property,
    personal,
    center,
    validate,
    login,
    footer,
    alter,
    home,
    navbar,
    problem
}

