module.exports = {
    home_page:'首頁',
    user_center:'數據',
    robot:'機器人',
    // rebate:'返傭',
    rebate:'獎勵',
    member:'會員',
    my:'我的',
    my_property:'我的資產',
    my_member:'我的會員',
    personal_data:'個人資料',
    safe:'安全設置',
    exit:'退出',

    user_center_tab1:'機器人收益',
    user_center_tab2:'收益统计',
    user_center_tab3:'收益查詢',

    robot_tab1:'机器人监测',
    robot_tab2:'机器人设置',
    robot_tab3:'机器人收益排行',

    safe_tab1:'我的資產',
    safe_tab2:'我的會員',
    safe_tab3:'個人資料',
    safe_tab4:'安全設置',

    sm_tab1:'數據中心',
    sm_tab2:'机器人',
    // sm_tab3:'返佣',
    sm_tab3:'獎勵',
    sm_tab4:'安全中心',

    center:'個人中心',

    drawer_tab1:'數據中心',
    drawer_tab2:'機器人中心',
    drawer_tab3:'返傭中心',
    drawer_tab4:'會員中心',

    exit_login_success:'退出登录成功 !',
    down_android: '下載Android',
    coming_online_soon: '下載iOS',
    go_back: '返回',
}
