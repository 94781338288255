module.exports={
    felton_quantitative_title: 'UPlus quantitative trading robot',
    felton_quantitative_content1: 'Based on big data and Virtual Currency Trading Robot',
    felton_quantitative_content2: 'Built by artificial intelligence,Let you lie down and make money',
    start_robot: 'Start the Robot',
    product_description: 'Product Description',

    golden_triangle: 'Golden Triangle\nArbitrage',
    stable_income: 'Stable Income',
    introduce: 'INTRODUCE',
    golden_triangle_tip: 'Three currencies form a closed loop, circular cross trading',
    stability: 'Stability',
    rate_return: 'Rate of Return',
    capture_big_market: 'Capture the Big Market',

    bb_transaction: 'BB\nTransaction',
    long_term_profit: 'Long-term profit',
    bb_transaction_tip: 'Buy and sell between different currencies to earn the difference',

    ub_transaction: 'UB\nTransaction',
    high_return: 'High return',
    ub_transaction_tip: 'Trade USDT with different currencies to earn USDT',

    grid_strategy: 'Grid\nstrategy',
    volatility_arbitrage: 'Volatility arbitrage',
    grid_strategy_tip: 'Automatically buy low and sell high in the volatile market, stable arbitrage',

    martin_strategy: 'Martin\nstrategy',
    money_management: 'Money management',
    martin_strategy_tip: 'Double the bet on one side to lower the cost and pull back the profit',

    contract_strategy: 'hedging contract transaction',
    stay_tuned_for: 'high yield',
    coming_soon: 'Long/short contract hedging losses, swing arbitrage, superb returns',

    investment_advice: 'Product advantages',
    reference_plan: 'Reference Plan',
    product_portfolio : 'Product Portfolio',
    membership_level: 'Membership Level',
    for_people: 'For People',
    features: 'Features',
    monthly_return: 'Monthly return',
    turn_on: 'Turn on',
    option_one: 'Option One',
    option_one_introduce: '2“Golden Triangle Arbitrage”\n + \n2“BB Transaction”',
    option_one_for_people: 'Bitcoin believers who hoard coins',
    option_one_features: 'Multi-currency transactions, large number of orders, multiple currencies',
    option_two: 'Option Two',
    option_two_introduce: '1“Golden Triangle Arbitrage”\n+\n5“BB Transaction”',
    option_two_for_people: 'Bitcoin believers who hoard coins',
    option_two_features: 'Suitable for earning profits in flat trading, not suitable for violent market fluctuations',
    option_three: 'Option Three',
    option_three_introduce: '2“UB Transaction”\n+\n1“BB Transaction”',
    option_three_for_people: 'Obtain long-term financial management income',
    option_three_features: 'Suitable for earning income when the market fluctuates sharply, there may be 30%-50% floating loss in the short term',
    no_suitable_solution: 'There is no suitable solution, try a ',
    custom_solution: 'custom solution?',

    product_advantages: 'Product Advantages',
    exclusive_strategy_model: 'Exclusive Strategy Model',
    exclusive_strategy_model_tip: 'Intelligent trading algorithm and refined position management, robust risk control system and flexible point capture',
    fully_automatic_unattended: 'Fully Automatic Unattended',
    fully_automatic_unattended_tip: 'Fool-style proxy investment, reduce time cost, make up for information asymmetry, and easily enjoy the income after bedtime',
    financial_security: 'Financial Security',
    financial_security_tip: 'Support API to access Binance and other major exchanges, digital assets are not transferable and always in their own account',
    regularly_update_policy: 'Regularly Update the Policy',
    regularly_update_policy_tip: 'A strong technical strategy team, according to market changes, regularly update the latest policy',

    opening_process: 'Opening Process',
    choose_exchange_connect: 'Choose an exchange and connect',
    recharge_fuel: 'recharge fuel'

}
