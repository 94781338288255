module.exports ={
    account_login: '账号登录',
    login_tips: '欢迎回来！ 使用您的电子邮件或者电话号码登录',
    account: '账号',
    password: '密码',
    login: '登录',
    save_pwd: '保存密码',
    forget_pwd: '忘记密码',
    register: '立即注册',
    input_phone_email: '请输入邮箱或者手机号',
    input_pwd: '请输入密码',
    input_phone: '请输入手机号',
    input_email: '请输入邮箱地址',
    input_code: '请输入验证码',
    sure_login_info: '请确认登录信息是否正确!',
    login_success: '登录成功',
    open_incognito_mode: '您已开启无痕模式，期间你的账号、密码將不会被存储',

    <!-- 注册 -->
    create_felton_account: '创建UPlus账号',
    email: '邮箱',
    mobile_phone: '手机号',
    input_email_number: '请输入邮箱',
    send_code: '发送验证码',
    resend_code: '后重新获取',
    input_invitation_code: '请输入邀请码',
    I_have_read_and_agree: '我已经阅读并同意',
    terms_of_service_of_felton: 'UPlus的用户协议',
    register_save: '注册',
    registered: '已有账号？',
    input_area_code: '请输入区号',
    input_check_code: '请输入校验码',
    read_terms_of_service: '请阅读服务条款',
    confirm_whether_registrationinfo_correct: '请确认注册信息是否正确！',
    regist_success: '注册成功',
    confirm_whether_phone_format_area_correct: '请确认手机号格式或区号是否正确！',
    code_sended_phone: '验证码已发送到您的手机！',
    input_right_email: '请输入正确的邮箱',
    code_sended_email: '验证码已发送到您的邮箱！',
    recommender_ID: '推荐人ID',
    optional: '选填',
    authCode: '验证码',

    <!-- 忘记密码页面 -->
    home_page: '首页',
    back_homepage: '返回首页',
    reset_password: '重置密码',
    input_new_pwd: '请输入新密码',
    next_step: '重置',
    confirm_entered_info_is_correct: '请确认输入信息是否正确！',
    password_setting_succeeded: '密码设置成功，请登录！',
    mobile_already_exists: '手机号已存在',
    email_already_exists: '邮箱已存在'

}
