module.exports={
    rebate_title:'邀請好友來賺錢',
    rebate_title_sub:'好友變豪友，一起有錢花',
    user_grade:'我的等級:',
    user_superior:'我的上級:',
    set_superior:'設置上級',
    drive_one:'直推獎勵 : ',
    drive_two:'團隊獎勵 : ',
    drive_two_1:'間推獎勵 : ',
    user_invite_code:'我的邀请码:',
    spread_link:'推廣鏈接: ',
    create_poster:'生成推廣海報',
    capital_account:'資金賬戶',
    trading_account:'交易賬戶(燃料費)',
    // rebate_account:'返傭獎金',
    rebate_account:'獎金',
    withdraw:'提現',
    withdraw_of:'提現中',
    top_up:'充值',
    transfer:'劃轉',
    total_performance:'總業績',
    until:'统计截止至 :',
    user_direct:'我的直推',
    common:'共',
    person:'人',
    invite_code:'邀請碼',
    user:'用戶',
    register_date:'註冊時間',
    vip_level:'vip等级',
    award_date:'獎勵時間',
    award_money:'獎勵金額(USDT)',
    billing_profit:'开单利润(USDT)',
    write_date:'紀錄時間',
    single_profit:'單筆利潤（USDT)',
    property_change:'資產變更（USDT)',
    property_balance:'資產餘額（USDT)',
    change_type:'變更類型',
    bonus_cut_in:'奖金划入',
    poster_title:'開啟UPlus，享受你的睡後收益！',
    spread_id:'推薦ID',
    download_poster:'下载推广海报',
    property_withdraw:'资产提现',
    withdraw_money:'提現金額',
    all:'全部',
    withdraw_address:'提現地址',
    withdraw_tip_info:'（幣安郵箱地址）',
    TRC20_address:'TRC20地址',
    submit_apply:'提交申請',
    property_withdraw_confirm:'資產體現確認',
    email_auth_code:'郵箱驗證碼',
    send_auth_code:'發送驗證碼',
    regain:'后重新获取',
    confirm_withdraw:'確認提現',
    set:'設置',
    please_input_withdraw_money:'請輸入提現金額',
    please_input_withdraw_address:'請輸入提現地址',
    please_input_email_auth_code:'请输入郵箱验证码',
    copy_success:'複製成功 !',
    please_bind_email:'请先绑定郵箱 !',
    auth_code_send_email:'验证码已发送到您的郵箱 !',
    withdraw_money_unable_empty:'提現金額不能為空 !',
    point_two:'最多只能輸入小數點後兩位 !',
    withdraw_address_unable_empty:'提現地址不能為空 !',
    email_format_incorrectness:'郵箱格式不正確 !',
    withdrawal_address_incorrect:'提現地址不正確 !',
    withdraw_info_input_error:'提现信息输入错误 !',
    withdraw_apply_sent:'提现申请已发送',
    please_input_superior_invitation_auth_code:'請輸入上級邀請碼 !',
    set_success:'設置成功 !',
    download_img:'下載圖片',

    recommend_count:'直推人数',
    total_performance1:'总业绩',
    member_type:'会员类型',
    robot_state:'机器人状态',
    robot_change_time:'机器人开/关时间',
    fuel_fee:'燃料费',
    reward_record_of_this_month: '本月獎勵紀錄',
    ordinary_users: '普通用户',
    rule: '規則',
}
