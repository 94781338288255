module.exports = {
    personal_data:'个人资料',
    avatar:'头像',
    nickname:'昵称',
    passport:'护照号码',
    birth_date:'出生日期',
    not_set:'暂未设置',
    please_input:'请输入',
    my_email:'我的邮箱',
    my_phone:'我的手机',
    not_bind_phone:'暂未绑定手机',
    not_bind_email:'暂未绑定邮箱',
    change:'更换',
    bind:'绑定',
    click_upload:'点击上传',
    upload_tip:'只能上传jpg/png文件，且不超过12MB',
    select_date:'选择日期',
    alter_nickname:'修改暱称',
    cancel:'取 消',
    confirm:'确 定',
    alter_passport:'修改护照',
    select_y_t_d:'选择年月日',
    please_input_nickname:'请输入昵称 !',
    please_input_passport:'请输入护照 !',
    nonsupport:'不支持特殊字符 !',
    alter_success:'修改成功 !',
    avatar_upload_success:'头像上传成功 !',
    img_err1:'图片只能是 JPG或者PNG 格式!',
    img_err2:'图片大小不能超过 12MB!'
}
