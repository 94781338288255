module.exports = {
    property_top_up:'Asset Recharge',
    main_net:'Mainnet',
    wallet_address:'Wallet address (this address can only receive USDT)',
    important_tip:'Important tip',
    least_top_up_money:'The minimum top-up amount is',
    top_up_tip1:'Please confirm the address carefully, it can\'t be retrieved if it is wrongly charged',
    top_up_tip2:'About 3~5 minutes to the account',
    confirm:'Confirm',
    get_tip:'Failed to obtain asset recharge information!',
    copy_success:'Copy successfully!',
}