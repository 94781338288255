module.exports = {
    robot_title_earn:'机器人收益（仅统计2021年9月1号以后的数据）',
    today_earn:'今日收益 :',
    add_app_earn:'累计收益 :',
    add_up_rate:'累计收益率 :',
    month_average_rate:'月平均收益率 :',
    total_earn_rank :'月收益排名 :',
    no_listed:'未入榜',
    robot_earn:'机器人收益',
    total_earn_rank_title:'本月收益排行(USDT) (我的排名:',
    else_list:'其它榜单',
    earn_statistics:'收益统计',
    everyday_earn_tip:'每日0:00-0:10分进行数据结算，0:10分更新数据',
    today:'今日',
    yesterday:'昨日',
    week:'本周',
    month:'本月',
    year:'本年',
    all:'全部',
    goods_type:'策略类型',
    transaction_order_num:'成交单数',
    earn_money:'收益金额',
    earn_query:'收益查询',
    select_month:'选择月',
    month_earn:'月收益',
    month_earn_rate:'月收益率',
    month_earn_statistics:'月收益统计',
    earn:'收益',
    day_earn_rate:'日收益率',
    today_earning_rate: '今日收益率',
    income_month: '本月收益',
    current_month_yield: '本月收益率',
    sure: '确 定',
    hide_no_profit: '隐藏0收益策略',
    profit_detail: '收益明细',
    share_profit: '分享收益',
    robot_day_profit: '机器人今日收益',
    robot_profit_total: '累计收益',
    add_up_rate_total: '累计收益率',
    date: '时间',
    transaction_order: '成交(单)',
    rate_total: '收益率',
    transaction_order_of_num: '成交数量(单)',
    details: '详情',
    see: '查看',
    data: '数据',
    daily_revenue_details: '每日收益详情',

//    排行
    total_earn_rank_r:'累计收益排行(USDT)',
    my_rank:'我的排名',

//    share
    robot_total_earn:'机器人累计收益',
    share_title:'致力于打造安全、便捷的策略交易平台',
    share_time:'分享时间',
    download_img:'下载图片'
}
