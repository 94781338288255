module.exports = {
    select_trading_on:'选择策略交易对',
    please_select_trading_on:'请选择策略交易对 !',
    please_select:'请选择',
    select_strategy_type:'选择策略类型',
    please_select_strategy_type:'请选择策略类型 !',
    param_detail:'参数详情',
    fold:'折叠',
    unfold:'展开',
    not_advice:'如果不会设置建议使用默认参数',
    recover_default:'恢复默认参数',
    full_order_money:'满单仓位',
    trendPeriod:'趋势周期',
    please_select_trendPeriod:'请选择趋势周期 !',
    firstOrderMoney:'首单额度',
    please_input_firstOrderMoney:'请输入首单额度 !',
    please_input_firstOrderMoney_error:'首单额度输入错误 !',
    cycleIndex:'循环次数',
    please_input_cycleIndex:'请输入循环次数 !',
    firstOrderTimes:'首单倍数',
    please_input_firstOrderTimes:'请输入首单倍数 !',
    orderNumber:'补仓次数',
    please_input_orderNumber:'请输入补仓次数 !',
    times:'次',
    stopProfitRatio:'止盈比例',
    please_input_stopProfitRatio:'请输入止盈比例 !',
    stopProfitCallback:'追踪止盈回调',
    please_input_stopProfitCallback:'请输入追踪止盈回调 !',
    stopProfitCallback_tip:'请先设置止盈回调再关闭 !',
    stopProfitCallback_tip2:'追踪止盈回调不建议大于止盈比例',
    coverConfig:'补仓配置',
    coverCallback:'追踪补仓回调',
    fullOrderStopLoss:'满单止损',
    stopLossRatio:'止损比例(基于尾单价格)',
    please_input_stopLossRatio:'请输入止损比例 !',
    full_order_money_exceed_limit:'满单仓位超出限制 !',
    strategy_success1:'生成策略成功',
    strategy_success2:'重启策略成功',
    strategy_success3:'修改策略成功',
    closed:'已关闭',
    have_set:'已设置',
    not_have_set:'未设置',
    close:'关闭',
    open:'开启',
    limited:'有限',
    infinite:'无限',
    cancel:'取消',
    confirm:'确定',
    minute:'分钟',
    alter_next_effect:'修改后下次循环开始生效',
    alter_dialog_tip:'修改<span style="font-weight: 600;color: red"> 补仓次数 </span>后，补仓配置和追踪补仓回调将恢复至默认参数',
    least_deal:'最少交易额度是',
    //tip
    not_input_decimals:'不能输入小数 !',
    cover_times_not_greater:'补仓次数不能大于',
    tip2:'首单倍数必须大于等于',
    tip3:'输入参数不能为',
    tip4:'止损比例必须小于'
}