module.exports = {
    home_page:'首页',
    user_center:'数据',
    robot:'机器人',
    // rebate:'返佣',
    rebate:'奖励',
    member:'会员',
    my:'我的',
    my_property:'我的资产',
    my_member:'我的会员',
    personal_data:'个人资料',
    safe:'安全设置',
    exit:'退出',
    user_center_tab1:'机器人收益',
    user_center_tab2:'收益统计',
    user_center_tab3:'收益查询',
    robot_tab1:'机器人监测',
    robot_tab2:'机器人设置',
    robot_tab3:'机器人收益排行',
    safe_tab1:'我的资产',
    safe_tab2:'我的会员',
    safe_tab3:'个人资料',
    safe_tab4:'安全设置',
    safe_tab5:'交易所API授权',
    sm_tab1:'数据中心',
    sm_tab2:'机器人',
    // sm_tab3:'返佣',
    sm_tab3:'奖励',
    sm_tab4:'安全中心',
    center:'个人中心',
    drawer_tab1:'数据中心',
    drawer_tab2:'机器人中心',
    drawer_tab3:'返佣中心',
    drawer_tab4:'会员中心',
    exit_login_success:'退出登录成功 !',
    down_android: '下载Android',
    coming_online_soon: '下载iOS',
    go_back: '返回',
}
