module.exports = {
    robot: '機器人',
    robot_dealer_name1: 'Binance機器人',
    robot_dealer_name2: 'Okx機器人',
    robot_monitoring: '機器人監視',
    robot_base_info: '機器人基本資訊',
    robot_name: '機器人名稱',
    robot_run_state: '狀態',
    closed: '已關閉',
    turned_on: '已開啟',
    robot_state_name1: '正在運行',
    robot_state_name2: '已停止運行',
    robot_state_name3: '已刪除',
    robot_state_name4: '服務切換中',
    robot_state_name5: '未綁定API',
    dormant: '休眠中',
    robot_run_time: '運行時長',
    hour: '小時',
    robot_asset_distribution: '機器人資產分佈',
    robot_total_assets: '總資產',
    welcome_to: '歡迎來到',
    robot_center: '機器人中心',
    fee_relief: '燃料費減免中',
    expire: '到期',
    register_address: '註冊地址',
    only_accounts_registered: '僅支持此連結注册成功的帳戶',

    <!--子组件开始-->
    robot_products: '機器人產品',
    auto_robot: '全自動機器人',
    sime_robot: '半自動機器人',
    auto_robot_tip: '啓動後機器人可自行分析市場行情並做出交易決定。',
    sime_robot_tip: '用戶需要根據對行情的分析判定設置參數。',
    content1: '說明：現貨3個 半自動4個 合約3個。',
    content3: '網格數量表示震蕩區間中分割的挂單小區間數量。比如區間100-400、等差、網格數3,則是分爲100-200、200-300、300-400這3個網格。等差：每相鄰兩檔挂單價格的差值相等；等比：每相鄰兩檔挂單價格的比值相等。',
    content4: '網格是一種在指定價格區間自動進行低買高賣的交易策略。用戶設定參數后,系統分割小網格自動挂單,隨著市場波動,策略低買高賣賺取波段收益。',
    yitianjjia: '已添加',
    haiketianjia: '還可添加',
    golden_triangle_arbitrage_tip: '三個幣種迴圈交易,活躍度高',
    bb_trade_tip: '任意兩個幣種交易,適合橫盤屯幣',
    ub_trade_tip: '用USDT交易貨幣,獲得長期理財收益',
    grid_strategy_tip: '在震盪行情中自動低買高賣,穩定套利',
    martin_strategy_tip: '單邊加倍押注拉低成本,回檔止盈',
    current_policy: '當前策略',
    historical_policy: '歷史策略',
    paused: '已暫停',
    cycle_completed: '循環已完成',
    cycle_stopped: '循環已停止',
    auto: '全自動',
    semi: '半自動',
    put_position: '投入倉位（個）',
    create_time: '創建時間',
    Arbitrage_times: '已套利次數',
    Total_revenue: '總收益',
    yield: '收益率',
    Manually_stopped: '已手動停止',
    average_position_price: '持倉均價',
    cycled: '已循環',
    replenishment_depth: '補倉深度',
    not_yet: '暫無',
    first_order: '首單',
    di: '第',
    ci: '次',
    floating_profit_loss: '浮動盈虧',
    current_price: '當前價格',
    strategy_details: '策略詳情',
    create_strategy: '创建策略',
    pause_strategy: '暫停策略',
    open_strategy: '開啟策略',
    del_strategy: '刪除策略',
    stop_strategy: '停止策略',
    restart_strategy: '重啓策略',
    pause: '暫停',
    stop: '停止',
    start: '開啟',
    restart: '重啓',
    total: '共',
    strip: '條',
    del: '刪除',
    number_of_cycles: '已循環次數',
    add: '添加',
    sj_tip: '一組黃金三角套利包含3個交易對。',
    select: '選擇',
    binance: '幣安',
    okx: '歐易',
    space_currency: '持倉幣種',
    supportive: '支持的',
    transaction_pair: '交易對',
    please_select: '請選擇',
    set_transaction_pair_position: '設定交易對倉位',
    sure: '確定',
    available: '可用',
    ub_tip: '機器人用於該幣種交易的金額（USDT）,建議倉位總額不要超過',
    available_balance: '可用餘額',
    grid_hokder: '請選擇要投入的幣種',
    grid_buy_holder: '請選擇要買入的幣種',
    grid_section1: '輸入預判的價格震蕩區間',
    grid_section2: '針對以上區間,確定分割的網格數量',
    grid_section3: '確定投入網格策略的金額',
    interval_lowest_price: '區間最低價',
    interval_maximum_price: '區間最高價',
    interval_tip: '區間最高價需大於區間最低價',
    grid_num: '網格數量',
    equal_difference: '等差',
    equal_ratio: '等比',
    grid_tip: '價格區間内的單網格收益率的預估值,已扣除手續費。',
    grid_ratio_tip1: '為保證網格收益,單格利潤率需大於1%',
    grid_ratio_tip2: '爲保証網格收益,單格利潤率需大於0%',
    input_amount_holder: '輸入金額',
    input_amount_tip1: '投入金額不能小於',
    input_amount_tip2: '投入金額不能超出可用金額',
    Estimated_ratio: '預計單網格利潤率',
    second_confirm: '下單二次確認',
    basic_settings: '基礎設定',
    grid_mode: '網格模式',
    donot_prompt: '不再提示',
    cancel: '取消',
    confirm: '確認',
    delete: '刪除',
    strategy: '策略',
    del_grid_tip: '删除網格策略後,機器人將停止對策略的監控及運行。',
    stop_martin_tip: '停止馬丁策略後,機器人將停止對策略的監控及運行。',
    strategy_cancel_order: '策略並撤單',
    strategy_no_cancellation: '策略但不撤單',
    strategy_cancel_order_radio1: '將撤掉該策略在交易所的相關掛單,因策略沒有完全執行可能產生虧損',
    strategy_cancel_order_radio2: '將撤掉該策略在交易所的相關掛單',
    strategy_cancel_order_radio2_tip: '撤單需要20-60分鐘，為避免撤單不徹底，期間請勿關閉機器人',
    strategy_no_cancellation_radio1: '不撤掉該策略在交易所的相關掛單,掛單到相應價位會繼續成交產生利潤',
    strategy_no_cancellation_radio2: '不撤掉該策略在交易所的相關掛單',
    quantity_transfinite: '數量超限',
    quantity_transfinite_auto0: '普通用戶最多可添加1個交易對（含UB、BB）,陞級為VIP可添加3個交易對、陞級為SVIP可添加8個交易對。',
    quantity_transfinite_auto1: 'VIP最多可添加3個交易對（含UB、BB）,陞級為SVIP可添加8個交易對。',
    quantity_transfinite_auto2: 'SVIP最多可添加8個交易對（含UB、BB、黃金三角套利）,超出不支持添加。',
    quantity_transfinite_auto3: 'YSVIP最多可添加12個交易對（含UB、BB、黃金三角套利）,超出不支持添加。',
    quantity_transfinite_semi0: '普通用戶最多可添加3個半自動交易對,陞級為VIP可添加8個交易對、陞級為SVIP可添加12個交易對。',
    quantity_transfinite_semi1: 'VIP最多可添加8個半自動交易對,陞級為SVIP可添加12個交易對。',
    quantity_transfinite_semi2: 'SVIP最多可添加12個半自動交易對,超出不支持添加。',
    quantity_transfinite_semi3: 'YSVIP最多可添加20個半自動交易對,超出不支持添加。',
    next_time: '下次再說',
    i_know: '我知道了',
    open_svip: '開通SVIP',
    sj_trade_tip: '黃金三角交易提示',
    sj_trade_content: '黃金三角套利交易僅限SVIP用戶使用,開通即享9大特權。',
    open_svip_tip: 'SVIP尊享黃金三角套利交易、BB交易、UB交易共8個交易對,聯系客服即可開通哦',
    order_amount_must_be: '下單金額必須',
    enter_content: '請輸入內容',
    position_currency_position: '持倉幣種倉位',
    transaction_currency_position: '交易幣種倉位',
    is_deal_pair: '的交易對',
    enter: '請輸入',
    is_trading_position: '的倉位',
    added_success: '添加成功',
    supported_transaction_currency: '支持的交易幣種',
    supported_transaction_pairs: '支持的交易對',
    enter_the_bin_amount: '請輸入倉位金額',
    position_amount_must_be: '倉位需',
    number_of_grids_cannot_be_less_than: '網格數量不能小於',
    number_of_grids_cannot_be_more_than: '網格數量不能大於',
    single_grid_profit_margin_must_be_greater_than: '單網格利潤率必須大於',
    input_amount_and_available_amount: '投入金額不能大於可用金額',
    are_you_sure_del_sj: '確定要刪除這條黃金三角交易嗎？',
    // are_you_sure_del_bb: '確定要刪除這條BB交易嗎？',
    are_you_sure_del_bb: '刪除BB交易後，機器人將停止對策略的監控及運行並強制撤掉全部掛單。',
    // are_you_sure_del_ub: '確定要刪除這條UB交易嗎？',
    are_you_sure_del_ub: '刪除UB策略後，機器人將停止對策略的監控及運行，如需撤單請至交易所所操作。',
    del_success: '刪除成功',
    you_sure_you_suspend_strategy_martin: '確定要暫停這條馬丁策略嗎？',
    you_sure_you_suspend_strategy_grid: '確定要暫停這條網格策略嗎？',
    policy_will_stop_completed: '策略執行完停止補單後將自動停止',
    policy_will_stop_clearance_sale: '策略執行完清倉賣出後將自動停止',
    only_input: '只投入',
    input_together: '同時投入',
    <!--子组件结束-->

    robot_ranking: '機器人排行榜',
    allow_other_users_to_view_my_policies: '允許其他用戶查看我的倉位',
    billing_list_of_week: '本周開單榜',
    // billing_list_of_week: '本周收益率榜',
    revenue_list_of_week: '本周收益榜',
    my_ranking: '我的排名',
    not_listed: '未入榜',
    see_policies: '看TA倉位',
    no_data: '暫無數據',
    all: '全部',
    sj: '黃金三角',
    bb: 'BB',
    ub: 'UB',
    grid: '網格',
    martin: '馬丁',
    order: '單',
    button_sure: '確定',
    everyone_space: 'TA的倉位設置',
    cumulative_billing_volume_week: '本周纍計開單量',
    golden_triangle_arbitrage: '黃金三角套利交易',
    bb_trade: '現貨全自動交易',
    ub_trade: 'UB交易',
    transaction_pair_added: '已添加交易對',
    init_space: '初始倉位',
    side: '方向',
    trade_currency: '交易幣種',
    grid_strategy: '現貨網格交易',
    martin_strategy: '現貨馬丁交易',
    deal_pair: '交易對',
    input_amount: '投入金額',
    input_coin: '投入幣種',
    Price_range: '價格區間',
    number_of_grids: '網格數量',
    policy_type: '策略類型',
    full_order_position: '滿單倉位',
    stop_ratio: '止盈比例',
    strategy_type_name0: '倉位馬丁策略',
    strategy_type_name1: '盈利預期馬丁策略',
    strategy_type_name2: '倉位&盈利預期雙馬丁策略',
    // close_robot_sure: '確定關閉機器人嗎？',
    close_robot_sure: '關閉機器人后將停止對策略的監控及運行',
    bind_binance_api_tip: '請先前往“安全設置”綁定Binance的API',
    bind_okx_api_tip: '請先前往“安全設置”綁定OKX的API',
    bind_api_tip: '請先前往“安全設置”綁定交易所的API',

    button_start_stop_name1: '啟動機器人',
    button_start_stop_name2: '關閉機器人',
    button_start_stop_name3: '已刪除',
    button_start_stop_name4: '服務切換中',
    button_start_stop_name5: '未綁定API',

    <!-- 子页面开始 -->
    policy_information: '策略資訊',
    policy_delegation: '策略委託',
    initial_held_assets: '初始持有資產',
    current_delegation: '當前委託',
    history_delegation: '歷史委託',
    history_transaction: '歷史成交',
    start_date: '開始日期',
    end_date: '結束日期',
    complete_deal: '完全成交',
    price: '價格',
    num: '數量',
    number_of_transactions: '成交數量',
    entrusted_quantity: '委託數量',
    date: '日期',
    time: '時間',
    average_transaction_price: '成交均價',
    end_time_cannot_less_than_start: '結束時間不能小於開始時間',
    start_time_cannot_creater_than_end: '開始時間不能大於結束時間',
    buy: '買',
    sell: '賣',
    cancelled: '已撤單',
    pending_order: '掛單中',
    completed: '已完成',
    transaction_price: '成交價格',
    revenue_details: '收益詳情',
    grid_hanging_list: '網格掛單',
    policy_info: '策略資訊',
    transaction_record: '成交記錄',
    total_annualization: '總年化',
    grid_profit: '網格利潤',
    grid_profit_tip: '每組已完成成交的網格產生的收益總和',
    floating_profit_loss_tip: '除去網格利潤以外，持有交易幣種產生的浮動盈虧',
    investment_amount: '投資額',
    investment_amount_tip: '開始策略時投入幣種的總和',
    currently_held_assets: '當前持有資產',
    single_grid_amount: '單網格金額',
    single_grid_num: '單網格數量',
    expected_single_grid_profit_margin: '預期單網格利潤率',
    price_when_strategy_started: '策略開啟時價格',
    number_of_pending_orders: '掛單成交次數',
    latest_market_price: '市場最新價格',
    pay_the_bill: '買單',
    selling_order: '賣單',
    number: '編號',
    entrusted_price: '委託價格',
    trading_up_and_down: '漲跌多少後成交',
    data_acquisition_in_progress: '數據獲取中',
    transaction_details: '成交詳細',
    buy_in: '買入',
    sell_out: '賣出',
    entrusted_time: '委託時間',
    transaction_time: '成交時間',
    transaction_volume: '交易額',
    service_charge: '手續費',
    del_grid_strategy: '刪除網格策略',
    equal_difference_value: '等差差值',
    total_arbitrage_times_grid: '網格總套利次數',
    negative_tip: '網格利潤减去手續費後為負數，建議重新設定網格策略。',
    operation: '操作',
    trading_direction: '交易方向',
    transaction_fee: '交易手續費',
    shortcut_settings: '快捷設定',
    cyclic_recording: '循環記錄',
    transaction_settings: '交易設定',
    stop_replenishment: '停止補單',
    start_replenishment: '開啟補單',
    clearance_sale: '清倉賣出',
    one_click_replenishment: '一鍵補倉',
    full_stop: '滿單止損',
    tracking_interference_stop_callback: '追踪止盈回調',
    tracking_replenishment_callback: '追踪補倉回調',
    secondary_cycle: '次循環',
    in_progress: '進行中',
    interference_stop_completed: '止盈完成',
    stop_loss_complete: '止損完成',
    completion_last_transaction_time: '完成/最後成交時間',
    income_amount: '收益金額',
    stop_martin_strategy: '停止馬丁策略',
    stop_policy_and_cancel_order: '停止策略並撤單',
    stop_strategy_no_cancellation: '停止策略但不撤單',
    stop_replenishment_tip: '停止補單後價格到補單位置將不再加倉',
    stop_replenishment_radio1: '僅本次循環停止補單',
    stop_replenishment_radio1_tip: '在本次循環中不再加倉，不影響下次循環的運行',
    stop_replenishment_radio2: '本次循環停止補單且完成後停止策略',
    stop_replenishment_radio2_tip: '本次循環不再加倉且完成後停止策略不在循環',
    clearance_sale_tip: '當前全部倉位將以市價掛單賣出',
    clearance_sale_radio1: '僅本次循環清倉賣出',
    clearance_sale_radio1_tip: '當前倉位清倉賣出，不影響下次循環的運行',
    clearance_sale_radio2: '本次循環清倉賣出並停止策略',
    clearance_sale_radio2_tip: '當前倉位全部賣出且停止策略不再循環',
    one_click_replenishment_tip: '本次補倉僅作用於當前循環，且不影響策略的補倉配置。因補倉命令會在追踪止盈追踪補倉等進程結束後執行，可能會有一定延遲。',
    replenishment_position: '補倉倉位',
    replenishment_position_tip1: '補倉金額不能小於最小金額',
    replenishment_position_tip2: '補倉金額不能大於可用餘額',
    replenishment_position_price: '補倉價格',
    stop_supplement_isrunning: '循環正在運行中，請稍後再試！',
    open_supplement_open_tip: '開啟補單後價格到補單位置將繼續買入，並按設置進行下一次循環',
    supplement_opened: '補單已開啟',
    operation_success: '操作成功',
    set_success: '設置成功',
    input_replenishment_position: '請輸入補倉金額',
    status_modified_success: '狀態修改成功',
    replenishment_amount_required: '補倉金額需',
    total_profit: '總利潤',
    done: '已做',
    trend_cycle: '趨勢週期',
    minutes: '分鐘',
    first_order_limit: '首單額度',
    replenishment_times: '補倉次數',
    times_of_cycles: '循環次數',
    stop_loss_ratio: '止損比例',
    infinite: '無限',
    deal_record: '交易記錄',
    see: '查看',
    replenishment: '補倉',
    interference: '止盈',
    loss: '止損',
    <!-- 子页面结束 -->

    <!-- new -->
    are_you_sure_del_grid: '確定要删除這條網格策略嗎？',
    are_you_sure_del_martin: '確定要停止這條馬丁策略嗎？',
    save: '保存',
    limitless: '無限',
    open: '開',
    close: '關',
    sj_error_tip: '您當前有其他全自動策略正在初始化，您可以先“保存”這條策略稍後再手動開啟',
    position_set_tip: '建議設置倉位不超過',
    transaction_tips: '此交易賺取的是',
    cancel_reset: '取消重啓',
    cancel_edit: '取消修改',
    start_: '啟動',
    restart_: '重新啟動',
    save_changes: '保存修改',
    add_martin: '添加馬丁策略',
    reset_martin: '重啓馬丁策略',
    set_trade: '交易設置',
    save_and_start: '保存並啓動',
    no_more_than_available_balance: '建議設置不超過可用餘額',
    batch_open: '批量開啟',
    select_all: '全選',
    del_grid_tip_stop: '机器人关闭状态删除策略将不执行撤单，如需撤单至交易所手动撤单',
    stop_martin_tip_stop: '因機器人處於關閉狀態，無法撤掉挂單需至交易所手動撤單。',
    please_fill_in_any_position: '至少填一份倉位',
    save_success: '保存成功',
    go_to_strategy_list_enable: '需要至列表批量開啓策略！',
    set_up_multiple_groups_golden: '如果設置多組黃金三角相同幣種，建議到列表同時批量開啓策略以保證收益最大化！',
    fee_relief_eight: '燃料費8折減免中',
    state: '狀態',
    initializing: '正在初始化',
    go_to_exchange_recharge: '請至交易所充值',
    purchase_price: '入塲價',
    delete_sj_for_closed_bot: '因機器人處於關閉狀態，無法撤掉掛單需至交易所手動撤單。',
    delete_sj_for_started_bot: '刪除黃金三角交易後，機器人將停止對策略的監控及運行並強制撤掉全部掛單。',
    min_position: '建議設置倉位不小於',
    contract_strategy: '合約策略',
    contract_strategy_tip: '多空對沖震盪套利，風險可控',
    grid_contract_strategy: '合約AI交易',
    input_grid_contract_amount: '設置投入策略的金額（USDT)',
    futures_currently_held: '當前持有的合約',
    trading_place: '交易所',
    long_order: '多單',
    short_order: '空單',
    cont: '張',
    earnings_change: '收益變化',
    pause_contract_tip: '暫停對沖合約交易後，機器人將停止對策略的監控及運行。',
    del_contract_tip: '刪除至尊對沖交易後，機器人將停止對策略的監控及運行。',
    pause_risk_tip: '我已知曉風險並自願承擔',
    liquidation_risk_tip: '有爆倉風險，需要去交易所手動撤單、平倉',
    select_risk_tip: '請選擇承擔風險',
    are_you_cancel_order: '是否全部撤單',
    cancel_contract_all_no_deal: '将自动撤销所有当前合约的未成交订单',
    retain_contract_all_no_deal: '未成交訂單將被保留在您的合約賬戶',
    are_you_close_position: '是否全部平倉',
    yes: '是',
    no: '否',
    closed_market_price_all: '將以市價平掉當前合約的所有倉位',
    retain_market_price_all: '未完結倉位將被保留在您的合約賬戶',
    del_hedge: '刪除對沖合約交易後，機器人將停止對策略的監控及運行，但不會撤掉挂單、不平倉，如需操作請至交易所手動處理。',
    add_position: '修改',
    add_contract_tip: '1000≤仓位>6000，币种:DOT，杠杆：10x\n' +
        '6000≤仓位>20000，币种:BTC，杠杆：20x\n' +
        '仓位≥20000，币种:BTC，杠杆：10x\n',
    margin_rate: '保證金率',
    add_position_long: '修改倉位',
    more_initial_amount: '輸入的倉位不能小於初始倉位',
    supreme_hedge: '對沖合約',
    add_position_success: '倉位修改成功',
    business_line: '業務綫',
    perpetual: '永續',
    position_type: '類型',
    cross: '全倉',
    order_Value: '委托價值',
    unfilled: '未成交',
    old: '原倉位',
    trading_position: '倉位',
    positions: '當前倉位',
    position_history: '歷史倉位',
    long: '多',
    short: '空',
    total_position: '持倉量',
    avg_price: '開倉均價',
    IMR: '初始保證金',
    mark_price: '標記價格',
    liq_price: '預估強平價',
    pl: '收益',
    opened: '開倉時間',
    avg_cost: '成本均價',
    avg_close_price: '平倉均價',
    max_open_int: '最大持倉量',
    closed_position: '已平倉量',
    closed_time: '全平時間',
    partial_close: '部分平倉',
    close_all: '全部平倉',
    stop_profit_stop_loss: '止盈止損',
    limit_market: '限價 | 市價',
    billing_quantity: '開單量',
    position_limit_description: '倉位限制根據交易所的最小交易量限制結合機器人設計計算得出。',
    select_hedge_coin: '選擇交易幣種',
    position_floating: '持倉浮動',
    profit_withdrawn: '已提取利潤',
    available_balance_hedge: '建議設置不超過可用餘額',
    test_is_full_tip: '本期内測名額已滿，下期内測將於6月開放，敬請期待哦。',
    risk_setting: '風險設置',
    hedge_stop_loss_tip: '倉位不足時是否止損',
    hedge_stop_loss_tip_two: '（基於最後一次加倉的價格止損）',
    hedge_stop_surplus_tip: '最高到達盈利（到達後策略將停止）',
    not_stop_loss: '不止損',
    not_stop_surplus: '不止盈',
    hedge_started_tip: '添加對沖合約交易時需要先關閉機器人再創建策略',
    hedge_create_dialog_tip: '燃料欠費會導致機器人關閉，停止對策略的監控運行，對合約策略會產生較大風險。 為避免此種情況的發生，限制開啟策略時燃料費大於本金的10%，例策略倉位為900USDT，那麼燃料費需大於等於90USDT。 燃料費不足時將有簡訊/郵件或客服提醒； 用戶可享受最近一次燃料費充值10%的授信額度； 燃料費可以隨時劃轉選取。',
    input_multiple: '請輸入300的倍數',
    enter_trade: '進入交易',
    no_trade_leave: '暫不交易，離開',
    enter_trade_tip: '請先認真閱讀並確認勾選《UPlus智能合約交易協議》',
    hedging_contract_trading_risk_title: 'UPlus智能合約交易風險提示',
    hedge_detail_tip1: '僅統計劃轉至交易賬戶的利潤',
    hedge_detail_tip2: '策略交易一次產生利潤為開1單',
    hedge_detail_tip3: '收益率=總收益/投入金額',
    hedge_detail_tip4: '所持倉位未平倉的浮動盈虧',
    hedge_detail_tip5: '策略所設置的倉位資金',
    hedge_detail_tip6: '交易所交易賬戶的USDT數量',
    exchange_assets: '交易所資產',
    funds_cannot_more_balance: '設置資金不能超過可用餘額',
    cannot_more_balance: '不可超過可用餘額',
    funds_set_tip: "為保證資金使用效率，建議設置為300的倍數",
    warn1: '使用智能合約交易是複雜且有風險的。您在交易合約時可能會實現大幅收益或損失，因此在開始交易前請做好充分準備。小幅價格波動也可能對您的交易產生很大的影響。 ',
    warn2: '當UPlus賬戶燃料費低於-10U時，智能合約交易將自動關閉，屆時會產生較高風險。 ',
    warn3: '請務必在開始交易前仔細閱讀《UPlus智能合約交易協議》。 ',
    warn4: '我已閱讀和了解相關協定，並已知曉風險',
    Click_view: '點擊查看',
    UPlus_Smart_furures_Trading_Agreement: '《UPlus智能合約交易協定》',
    stop_loss: '止损',
    please_select_currency: '請選擇交易幣種',
    loss_warn_tip1: '策略風險較高時會平掉部分倉位以釋放保證金抵禦風險，平掉倉位的浮虧會轉為實虧。 後續盈利優先補虧損，補虧損不消耗燃料費。',
    loss_warn_tip2: '想要扛單至行情回檔可選擇不止損。 策略使用的是全倉保證金，可能會虧掉交易帳戶倉位設定以外的保證金，需留意風險。',
    hedge_balance_tip_b: 'Binance合約帳戶資金減其他策略佔用資金',
    hedge_balance_tip_o: 'Okx交易帳戶資金-其他策略佔用資金',
    Abnormal_Reminder: '異常提醒',
    warn_dialog_title: "機器人運行異常,請及時修復",
    Recharge: "去充值",
    fill_up_positions: "補齊倉位",
    input_fillup_position: "請輸入補齊金額",
    amount: "應補金額",
    input_position: "投入倉位",
    available_fill_position: "可用資金",
    make_up_loss_ratio: "補虧損比例",
    risk_level: "風險等級",
    spot_limit_tip: "您的現貨全自動交易已經添加上限，請删除後再繼續添加！",
    futures_limit_tip: "您的合約交易已經添加上限，請删除後再繼續添加！",
    semi_limit_tip: "您的現貨半自動交易已經添加上限，請删除後再繼續添加！",
    minimum_position: "策略倉位預覽",
    input_min_position: "最小投入倉位",
    futures_products: "合約產品",
    Spot_product: "現貨產品",
    futures_spot: "期現產品",
    futures_spot_strategy: '期現AI交易',
    orders: '委托',
    futures_tip1_3: "風險不敏感，追求高額收益，可忍受較多本金損失",
    no_stop_loss: "不止損",
}
