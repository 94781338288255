const robot = require('./language/zh/robot')
const user_center = require('./language/zh/user_center')
const rebate=require('./language/zh/rabate')
const member_right= require('./language/zh/grant_member/member_right')
const member=require('./language/zh/grant_member/member')
const martinComments=require('./language/zh/martin/martinComments')
const detail_param=require('./language/zh/martin/detail_param')
const transfer=require('./language/zh/user/transfer')
const top_up=require('./language/zh/top_up')
const property=require('./language/zh/user/property')
const personal=require('./language/zh/user/personal')
const center=require('./language/zh/center')
const validate=require('./language/zh/validate')
const help = require("./language/zh/help");
const login = require("./language/zh/login");
const footer = require("./language/zh/footer");
const alter = require("./language/zh/alter")
const home = require("./language/zh/home")
const navbar = require("./language/zh/navbar")
const tutorial = require("./language/zh/tutorial")
const problem = require("./language/zh/problem")
module.exports = {
    message: {
        login: '登录',
        Username: '用户名',
        Password: '密码',
        Captcha: '验证码',
        Language: '语言',
        zh: '中文',
        en: '英文',
        footer_title:'致力于打造安全、便捷的策略交易平台，带来简单、极致、高效的量化体验。',
    },
    robot,
    user_center,
    rebate,
    member_right,
    member,
    martinComments,
    detail_param,
    transfer,
    top_up,
    property,
    personal,
    center,
    validate,
    help,
    login,
    footer,
    alter,
    home,
    navbar,
    tutorial,
    problem
}



