<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data(){
    return{
      selectLanguage:''
    }
  },
  created() {
    // if (this.$route.path=='/') {
    //   this.selectLanguage='English'
    // } else {
      if (localStorage.getItem('language')){
        this.selectLanguage = localStorage.getItem('language')
      } else {
        this.selectLanguage = '繁体中文'
      }
      this.selectLanguage==='繁体中文' ? this.$i18n.locale='zh-tw' : this.selectLanguage==='简体中文' ? this.$i18n.locale='zh' : this.$i18n.locale='en'
    // }
  }
}
</script>

<style>
@import "./assets/css/global.css";
</style>
