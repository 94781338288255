module.exports={
    go_back:'返回前頁',
    member_center:'會員中心',
    user_activation_code:'使用激活碼',
    member_right:'會員權益',
    member_title:'極致量化體驗 盡享財富盛宴',
    member_title_sub:'開通',
    member_title_sub2:'尊享多重服務',
    renew_eight_discount:'續費限時8折',
    upgrade:'升级为',
    please_input_member_activation_code:'請輸入會員激活碼',
    conversion:'兌換',
    confirm_order_form:'確認訂單',
    member_privilege:'會員特權/年',
    order_money:'訂單金額',
    property_money:'資產金額',
    balance_lack:'僅支持資產餘額支付,當前餘額不足,請先',
    top_up:'充值',
    support_type:'僅支持資產餘額支付',
    please_input_email_auth_code:'請輸入郵箱驗證碼',
    please_input_phone_auth_code:'請輸入手機驗證碼',
    send_auth_code:'發送驗證碼',
    regain:'後重新獲取',
    confirm_open:'確認開通',
    property_top_up:'資產充值',
    main_net:'主網',
    wallet_address:'錢包地址（此地址只可接收USDT）',
    important_tip:'重要提示',
    least_top_up_money:'最少充值金額爲',
    top_up_tip1:'請仔細確認地址，充錯無法找回',
    top_up_tip2:'大約3~5分鐘到賬',
    user_already_top_up:'我已充值',
    conversion_success:'兌換成功,您已成為',
    perpetual:'永久',
    i_see:'我知道了',
    please_input_activation_auth_code:'請輸入激活碼',
    please_input_auth_code:'請輸入验证码',
    perpetual_valid:'永久有效',
    expire:'到期',
    not_yet_open:'尚未開通',
    balance_lack_tip:'餘額不足 !',
    sent_phone_auth_code:'驗證碼已發送到您的手機 !',
    sent_email_auth_code:'驗證碼已發送到您的手機 !',
    copy_success:'复制成功 !',
    get_balance_error:'获取资产充值信息失败 !',
    demotion_buy_warn:'您當前已經是SVIP,不可降級購買VIP !',
    property_money_new: '資金賬戶餘額',
    balance_lack_new:'僅支持資金賬戶餘額支付,餘額不足,請先',
    support_type_new:'僅支持資金賬戶餘額支付',

    //会员
    my_member:'我的會員',
}
