module.exports = {
    bind_login_phone: 'Bind login phone number',
    change_login_phone: 'Change login phone number',
    your_phone: 'phone number',
    old_phone_code: 'Old phone verification code',
    send_code: 'Send the verification code',
    resend_code: 'Resend verification code',
    new_phone: 'New Phone Number',
    new_phone_code: 'New phone verification code',
    phone_code: 'Phone verification code',
    cancel: 'Cancel',
    sure: 'Sure',
    change_email: 'Change email address',
    bind_email: 'Bind email address',
    email_address: 'Email address',
    old_email_code: 'Old email verification code',
    new_email_address: 'New email address',
    new_email_code: 'New email verification code',
    email_code: 'Email verification code',
    input_old_phone: 'Please enter your old phone number',
    input_code: 'please enter verification code',
    input_new_phone: 'Please enter a new phone number',
    input_old_email_address: 'Please enter old email address',
    input_old_email_code: 'Please enter the old email verification code',
    input_new_email_address: 'Please enter a new email address',
    input_new_email_code: 'Please enter the new email verification code',
    sure_phone_format: 'Please make sure the phone number format is correct!',
    code_sended_phone: 'The verification code has been sent to your phone!',
    sure_new_email_format: 'Please make sure the new email format is correct!',
    code_sended_email: 'The verification code has been sent to your email!',
    sure_old_email_format: 'Please make sure the old email format is correct!',
    bind_email_first: 'Please bind your email first!',
    input_phone_error: 'Phone number entered incorrectly!',
    change_phone_success: 'The phone number has been changed successfully!',
    bind_phone_success: 'The phone number is bound successfully!',
    input_email_error: 'Email address input error!',
    change_email_success: 'Email Replacement Successful!',
    bing_email_success: 'Email binding is successful!',

    <!-- 安全中心 -->
    basic_info: 'Basic information',
    phone_number: 'Login phone',
    phone_num_tip: 'Used for verification when logging in, withdrawing coins, retrieving passwords, modifying security settings, and managing APIs',
    change: 'change',
    binded: 'bind',
    unbind: 'unbind',
    bind_email_address: 'Bind email',
    email_tip: 'You can bind a common email address for login, password retrieval, and confirmation when withdrawing coins',
    login_pwd: 'Login password',
    pwd_tip: 'Used to protect account security',
    alter: 'modify',
    bing_exchange: 'Bind exchange',
    registered_address: 'registered address',
    priority_display: 'Priority display',
    set_priority: 'Set as priority display',
    register_address_tip: '(Only accounts successfully registered with this link are supported)',
    change_pwd: 'Change login password',
    old_pwd: 'Original login password',
    new_pwd: 'New login password',
    confirm_pwd: 'Confirm new password',
    change_api_info: 'Modify API information',
    unbind_phone: 'Unbind phone',
    unbind_email: 'Unbind email',
    input_old_pwd: 'Please enter the old password',
    input_new_pwd: 'Please enter a new password',
    input_new_pwd_agin: 'Please enter the new password again',
    please_enter: 'Please enter',
    input_phone_code: 'Please enter the phone verification code',
    input_email_code: 'Please enter the email verification code',
    input_right_info: 'Please enter the correct information!',
    pwd_inconsistent: 'The two password entries are inconsistent!',
    pwd_alter_success_to_login: 'The password has been changed successfully, please log in again!',
    change_api_info_success: 'API information modified successfully!',
    go_to_robot_close_robot: 'Please go to the "Robot" page to close the robot, and then operate the unbinding API!',
    are_you_sure_unbind_api: 'Are you sure you want to unbind this api?',
    unbind_success: 'Unbind successfully',
    set_priority_success: 'Set priority success',
    input_old_code: 'please enter the original mobile phone verification code',
    input_new_code: 'please enter the new mobile phone verification code',
    copy: 'Copy',
    passphrase: 'passphrase',
    verify_message: 'Verify message',
}
