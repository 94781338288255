module.exports={
	"product_info_title": "UPlus Product Introduction",
	"product_info": "Our products belong to quantitative strategy trading, automatic trading, no need to set up, no need to manually open and close positions, bind the exchange API to our platform, and authorize the trading authority. On the exchange, it is safe and risk-free. The software is free to use, and the profit share will be deducted from the income, and 25% of each profit will be deducted! We do not have the right to directly deduct profits, so members are required to recharge some USDT in the APP in advance. The recharged USDT Can be used as fuel or to buy point cards.",
	"start_robot_condition": "Conditions to start the robot",
	"start_robot_condition_tip": "The fuel or point card must be greater than or equal to 10% of the principal to start the robot",
	"Strategy_Instructions": "Strategy Selection Instructions",
	"Strategy_Instructions_tip": "There are two strategies: contract and spot. The contract earns USDT, and the spot earns currency. Considering the stability of the strategy in terms of contract quantification, only some currencies have been launched for the time being, but the transaction earns USDT, and it does not matter which trading pair you choose. Spot is applicable to all currencies!",
	"Contract_Trading_Instructions": "Contract Strategy Trading Summary",
	"Contract_Trading_Instructions_tip": "A simple understanding of the trading strategy belongs to long-short hedging and arbitrage. The \"exclusive mathematical model\" is used to accurately grasp the buying and selling opportunities, so as to achieve refined position management, powerful risk control and flexible market capture. From a macro point of view, the strategy can adapt to most markets, and cover losses in low-probability markets with high returns in most markets to ensure high rates of return in long-term operation.",
	"Modify_position_description": "Modify Strategy Position Description",
	"Modify_position_description_tip": "The current modified position can only be increased but not decreased. Find the modification strategy in the current strategy, click Modify strategy, enter the new USDT amount, and click to confirm the modification! The new position may not take effect immediately after modification, but Gradual update is completed under the condition of ensuring the normal operation of the policy.",
	"Stop_Loss_Instructions": "Stop Loss Instructions",
	"Stop_Loss_Instructions_tip_title1": "Policy settings stop loss:",
	"Stop_Loss_Instructions_tip_content1": "If you want to carry the order until the market pulls back, you can choose to stop loss. The strategy uses a cross margin, which may lose the margin other than the trading account position setting, so you need to pay attention to the risks.",
	"Stop_Loss_Instructions_tip_title2": "Strategy setting stop loss:",
	"Stop_Loss_Instructions_tip_content2": "1. When the risk of the strategy is high, some positions will be closed to release the margin to defend against risks, and the floating losses of closed positions will be turned into real losses. Subsequent profits will give priority to making up for losses, and making up losses will not consume fuel fees and point cards. ;\n2. The robot will monitor the risk level of the strategy in real time. When the risk is high, it will automatically stop loss, which may trigger multiple stop losses, and the maximum stop loss is 75% of the total position.",
	"Card_Fuel_Instructions": "Instructions for Points and Fuel",
	"Card_Fuel_Instructions_tip": "Both point cards and fuel are used to deduct profit sharing. Point cards or fuel costs are deducted from profits to deduct profits. The difference is that there are discounts when purchasing point cards, but if there is any remaining, it cannot be withdrawn. Go, and there is no discount for fuel and you can pick it up!",
	"Trading_account_loss": "Account floating loss description",
	"Trading_account_loss_tip": "This kind of trading logic of double opening of long and short makes the trading account sometimes floating loss and sometimes floating profit. This is normal. Floating losses cannot be counted as losses. If there is an actual stop loss, the loss will be recorded, and 70% of the profit generated by each transaction will be prioritized to make up for the loss (this part of the profit will not be deducted in the process of making up for the loss), until the loss is made up, 30% of the remaining profit will still be deducted!",
	"Withdrawal_Instructions": "Withdrawal Instructions",
	"Withdrawal_Instructions_tip": "Withdrawals require manual review and will arrive within 24 hours, including holidays!",
	"Exclusive_user_introduction": "Exclusive user introduction",
	"Exclusive_user_introduction_tip1": "Okx exclusive user: through OKX exclusive link",
	"Exclusive_user_introduction_tip2": "Or an exchange member registered with an invitation code (UPLUS3).\nBinance exclusive users: users who apply for the Binance API, verify and bind successfully through the UPlus platform.\nExclusive user benefits: exclusive users enjoy 98% gas discount for a limited time.",
	"Deduction_Instructions": "Deduction Instructions",
	"Deduction_Instructions_tip": "The software is free to use, and the profit share is deducted from the income, and 25% of each profit is deducted! We do not have the right to directly deduct profits, so members are required to recharge some USDT in the APP in advance. The recharged USDT can be used as fuel or to purchase point cards. Point cards and fuel are used to deduct profit sharing!",
	"must_read_newbies": "A must read for newbies",
	"must_read_newbies_content1_1": "1. The contract trading strategy is a high-frequency hedging transaction. It does not judge the market, does not guess the ups and downs, and continues to arbitrage through price fluctuations, so the principal may fluctuate to a certain extent, generally within 20%, how much does it fluctuate? Related to the market, the principal will gradually increase if you continue to run, and the novice can accept it and then open it.",
	"must_read_newbies_content1_2": "Don't do manual operations too often, it will affect the operation of the robot, reduce the profit and increase the risk.",
	"must_read_newbies_content1_3": "In addition, the point card cannot be refunded after purchasing it, so if you decide to use it for a long time before purchasing the point card, you can recharge it to the fuel account to start running.",
	"must_read_newbies_content2_0": "2. Please be cautious of manually misappropriating funds in the exchange.",
	"must_read_newbies_content2": " After the transfer, the robot will trade abnormally, and orders cannot be opened normally. In severe cases, there will be a risk of liquidation.",
	"must_read_newbies_content3": "3. The accumulated positions of robots are complicated and slow. The system will automatically deploy them in a long period of time, and have accurate calculation of profits. Novices should not delete robots frequently and add them again. No floating loss does not mean that all profits have been settled. The position is the future profit, and there is no profit without the position. When the market comes, the profit of the position that loses more profit will be much lower."
}
