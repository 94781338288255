module.exports = {
    capital_transfer:'資金劃轉',
    from:'從',
    to:'到',
    balance:'餘額',
    please_select:'请选择',
    transfer_money:'劃轉金額',
    all:'全部',
    confirm_transfer:'確認劃轉',
    capital_account:'資金賬戶',
    trading_account:'燃料賬戶',
    // rebate_account:'返傭獎金',
    rebate_account:'獎金',
    please_input_transfer_money:'請輸入劃轉金額 !',
    restrict_input:'最多只能輸入小數點後兩位 !',
    transfer_success:'劃轉成功 !',
    warn_not:'不能劃轉到返傭獎金 !',
    not_negative_numbers: '劃轉金額需≥0',
}
