module.exports = {
    bind_login_phone: '绑定登录手机',
    change_login_phone: '更换登录手机',
    your_phone: '手机号码',
    old_phone_code: '旧手机验证码',
    send_code: '发送验证码',
    resend_code: '后重新获取',
    new_phone: '新手机号码',
    new_phone_code: '新手机验证码',
    phone_code: '手机验证码',
    cancel: '取 消',
    sure: '确 定',
    change_email: '更换邮箱',
    bind_email: '绑定邮箱',
    email_address: '邮箱地址',
    old_email_code: '旧邮箱验证码',
    new_email_address: '新邮箱地址',
    new_email_code: '新邮箱验证码',
    email_code: '邮箱验证码',
    input_old_phone: '请输入旧手机号',
    input_code: '请输入验证码',
    input_new_phone: '请输入新手机号',
    input_old_email_address: '请输入旧邮箱地址',
    input_old_email_code: '请输入旧邮箱验证码',
    input_new_email_address: '请输入新邮箱地址',
    input_new_email_code: '请输入新邮箱验证码',
    sure_phone_format: '请确认手机号码格式是否正确！',
    code_sended_phone: '验证码已发送到您的手机！',
    sure_new_email_format: '请确认新邮箱格式是否正确！',
    code_sended_email: '验证码已发送到您的邮箱！',
    sure_old_email_format: '请确认旧邮箱格式是否正确！',
    bind_email_first: '请先绑定邮箱！',
    input_phone_error: '手机号输入错误！',
    change_phone_success: '更换手机号成功！',
    bind_phone_success: '绑定手机号成功！',
    input_email_error: '邮箱输入错误！',
    change_email_success: '邮箱更换成功！',
    bing_email_success: '绑定邮箱成功！',

    <!-- 安全中心 -->
    basic_info: '基础信息',
    phone_number: '登录手机',
    phone_num_tip: '用于登录、提币、找回密码、修改安全设置、管理API时进行验证',
    change: '更换',
    binded: '绑定',
    unbind: '解绑',
    bind_email_address: '绑定邮箱',
    email_tip: '您可以绑定一个常用邮箱，用于登录、找回密码、提币时的确认',
    login_pwd: '登录密码',
    pwd_tip: '用于保护账户安全',
    alter: '修改',
    bing_exchange: '绑定交易所',
    registered_address: '注册地址',
    priority_display: '优先展示',
    set_priority: '设置为优先展示',
    register_address_tip: '（仅支持此链接注册成功的账户）',
    change_pwd: '修改登录密码',
    old_pwd: '原登录密码',
    new_pwd: '新登录密码',
    confirm_pwd: '确认新密码',
    change_api_info: '修改API信息',
    unbind_phone: '解绑手机',
    unbind_email: '解绑邮箱',
    input_old_pwd: '请输入旧密码',
    input_new_pwd: '请输入新密码',
    input_new_pwd_agin: '请再次输入新密码',
    please_enter: '请输入',
    input_phone_code: '请输入手机验证码',
    input_email_code: '请输入邮箱验证码',
    input_right_info: '请输入正确的信息！',
    pwd_inconsistent: '两次密码输入不一致！',
    pwd_alter_success_to_login: '密码修改成功,请重新登录!',
    change_api_info_success: 'API信息修改成功！',
    go_to_robot_close_robot: '请先前往“机器人”页面关闭机器人，再操作解绑API！',
    are_you_sure_unbind_api: '确定要解绑这条api？',
    unbind_success: '解绑成功',
    set_priority_success: '设置优先成功',
    input_old_code: '请输入原手机验证码',
    input_new_code: '请输入新手机验证码',
    copy: '复制链接',
    passphrase: '密码短语',
    verify_message: '验证信息',
}
