module.exports = {
    s_vip_member:'SVIP annual member',
    vip_member:'VIP annual member',
    vip_enjoy:'VIP member exclusive',
    big_rights:'Big rights',
    member_title:'Fully automatic quantization belongs only to you',
    member_title_sub1:'Super profitable member account, open',
    enjoy:'Exclusive',
    //content
    content_title:'Exclusive Golden Triangle Arbitrage Trading',
    content_info:'Exclusive Golden Triangle Arbitrage Trading',
    content_info: 'The optimal quantitative trading strategy developed by the platform is exclusively for SVIP users to accelerate the opening of the door to wealth freedom',
    high_reach:'High to',
    content_title2:'Add privileges to a transaction pair',
    content_info2_s:'Users can add up to 8 automatic trading pairs and 12 semi-automatic trading pairs to fully meet the trading needs of different users',
    content_info2_v:'Users can add up to 3 automatic trading pairs and 8 semi-automatic trading pairs, which can meet the user\'s basic trading needs',
    content_title3:'Dedicated server guarantee',
    content_info3_s:'Specially build an exclusive server for SVIP users, the transaction signal is fleeting, and the exclusive service guarantees the smooth operation of the transaction',
    content_title4:'Limited skin to show identity',
    content_info4_s:'The SVIP member version of the custom skin will be launched soon to meet individual needs and demonstrate distinguished status',
    content_title5:'Customized weekly report tracking data',
    content_info5_s_min:'coming soon',
    content_info5_s:'Users launch a customized weekly report service, and push transaction data and operation suggestions for users every week',
    content_title6:'Golden customer service 1 to 1 service',
    content_info6_s:'1-to-1 service, answering questions for users, teaching knowledge about automatic quantitative investment',
    content_title7:'Members Club',
    content_info7_s:'A gathering place for like-minded quantitative investors, a private interaction for exchange of views and collision of wisdom',
    content_title7_svip:'20% off fuel',
    content_info7_s_svip:'SVIP users enjoy a 20% discount on fuel fees, unlimited strategies and no upper limit of reduction',
    content_title8:'Priority participation in activities',
    content_info8_s:'The platform occasionally launches preferential activities with limited quotas. The higher the level, the priority to enjoy the right to participate in the activity',
    content_title9:'Exclusive member benefits',
    content_info9_s:'The platform launches welfare activities from time to time to give back to users, and enjoy the exclusive welfare of this level according to the user leve',
//
    confirm_agreement:'Confirming the activation means agreeing to the 《Felton Member Service Agreement》',
    year:'year',
    prime_price:'original price',
    eight_renew:'20% off renewal fee',
    // eight_renew:'renewal',
    buy_it_now:'Grab it now',

    // my member alone
    user_member1:'The highest user can add',
    user_member2:'12 fully automated trading pairs 20 semi-automated trading pairs',
    user_member3:'8 fully automated pairs and 12 semi-automated pairs',
    user_member4:'3 fully automated pairs of 8 semi-automated pairs',
    user_member5:'Fully satisfy the trading needs of different users',
    user_member6:'Satisfy the basic trading needs of users',
    After_trial_expires_tip: '試用到期恢復至1980',
}
