module.exports = {
    s_vip_member:'SVIP年度会员',
    vip_member:'VIP年度会员',
    vip_enjoy:'VIP会员尊享',
    big_rights:'大权益',
    member_title:'极致量化体验 尽享财富盛宴',
    member_title_sub1:'开通',
    enjoy:'尊享',
    //content
    content_title:'专享黄金三角套利交易',
    content_info:'平台潜心研发的最优量化交易策略，特供SVIP用户专享，加速开启财富自由之门',
    high_reach:'高至',
    content_title2:'个交易对添加特权',
    content_info2_s:'用户最高可添加8个全自动交易对12个半自动交易对，充分满足不同用户的交易需求',
    content_info2_v:'用户最高可添加3个全自动交易对8个半自动交易对，可满足用户的基础交易需求',
    content_title3:'专属服务器保障',
    content_info3_s:'为SVIP用户特别搭建专属服务器，交易信号稍纵即逝，专属服务保障交易顺畅运行',
    content_title4:'限定皮肤彰显身份',
    content_info4_s:'即将推出SVIP会员版定制皮肤，满足个性化需求，彰显尊贵身份',
    content_title5:'定制周报追踪数据',
    content_info5_s_min:'即将为',
    content_info5_s:'用户推出定制周报服务，每周为用户推送交易数据及操作建议',
    content_title6:'金牌客服1对1服务',
    content_info6_s:'1对1服务，为用户答疑解惑，讲授全自动量化投资相关知识',
    content_title7:'会员俱乐部',
    content_info7_s:'志趣相投的量化投资人聚集地，观点交流、智慧碰撞的私享互动',
    content_title7_svip:'燃料费享8折优惠',
    content_info7_s_svip:'SVIP用户专享燃料费8折优惠，不限策略，没有减免上限',
    content_title8:'活动优先参与权',
    content_info8_s:'平台不定期推出限定名额的优惠活动，级别越高越优先享有活动参与权',
    content_title9:'专属会员福利',
    content_info9_s:'平台不定期推出福利活动回馈用户，根据用户级别享有该级别的专属福利',
//
    confirm_agreement:'确认开通即视为同意《UPlus会员服务协议》',
    year:'年',
    prime_price:'原价',
    eight_renew:'8折续费',
    // eight_renew:'续费',
    buy_it_now:'立即抢购',

    //我的会员单独
    user_member1:'用户最高可添加',
    user_member2:'12个全自动交易对20个半自动交易对',
    user_member3:'8个全自动交易对12个半自动交易对',
    user_member4:'3个全自动交易对8个半自动交易对',
    user_member5:'充分满足不同用户的交易需求',
    user_member6:'可满足用户的基础交易需求',
    After_trial_expires_tip: '试用到期恢复至1980',
}
