export default {
    currentDate(format) {
        let date = new Date()
        return date.getFullYear().toString() + format + ((date.getMonth() + 1)<10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1)).toString() + format + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
    },

    formatDate(currentDate,format) {
        let date1 = new Date().getTime() + currentDate*24*60*60*1000
        let date = new Date(date1);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        // let h = date.getHours();
        // h = h < 10 ? ('0' + h) : h;
        // let m = date.getMinutes();
        // m = m < 10 ? ('0' + m) : m;  2021-12-09
        // let s = date.getSeconds();
        // s = s < 10 ? ('0' + s) : s;
        return y + format + MM + format + d;
    },

    formatDate_c(currentDate,format) {
        // let date1 = new Date(currentDate).getTime()
        let date = new Date(currentDate);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        // let h = date.getHours();
        // h = h < 10 ? ('0' + h) : h;
        // let m = date.getMinutes();
        // m = m < 10 ? ('0' + m) : m;  2021-12-09
        // let s = date.getSeconds();
        // s = s < 10 ? ('0' + s) : s;
        return y + format + MM + format + d;
    },

    formatDate_dd(currentDate,format) {
        // let date1 = new Date(currentDate).getTime()
        let date = new Date(currentDate);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return y + format + MM + format + d + ' ' + h + ':' + m + ':' + s;
    },

    formatDate_no_year(currentDate,format) {
        // let date1 = new Date(currentDate).getTime()
        let date = new Date(currentDate);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return MM + format + d+ ' '+ h+':'+m;
    },

    formatDuring(mss) {
        var days = parseInt(mss / (1000 * 60 * 60 * 24));
        var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = ((mss % (1000 * 60)) / 1000).toFixed(0);
        return days + "日" + hours + "時" + minutes + "分" + seconds + "秒";
    },

    formatDuringToHour(mss) {
        // var days = parseInt(mss / (1000 * 60 * 60 * 24));
        // var hours = parseInt((mss / (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var hours = parseInt(mss / (1000 * 60 * 60));
        // var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
        // var seconds = ((mss % (1000 * 60)) / 1000).toFixed(0);
        return hours;
    },

    filters: {
        formatDates: function (value) {
            let date = new Date(value);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM;
            let d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            let h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            let m = date.getMinutes();
            m = m < 10 ? ('0' + m) : m;
            let s = date.getSeconds();
            s = s < 10 ? ('0' + s) : s;
            return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
        }
    },

    num_intercept(num){
        var re = /([0-9]+\.[0-9]{2})[0-9]*/;
        return num.replace(re,"$1");
    },


    lock() {
        document.documentElement.style.overflow = "hidden";
        document.documentElement.style.position = "fixed";
        document.body.scroll = "no";
    },

    unLock() {
        document.documentElement.style.overflow = "scroll";
        document.documentElement.style.position = "relative";
        document.body.scroll = "yes";
    },

    _isMobile() {
        let flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        return flag;
    },
    isAndroid() {
        let flag = navigator.userAgent.indexOf('Android')>-1;
        return flag;
    },

    formatUnix(inputTime){
        if (!inputTime) return
        inputTime = inputTime.replaceAll('-', '/')
        var date = new Date(inputTime).getTime()
        return date;
    },

    // 时间格式化1
    formatDateTimeOne(inputTime) {
        var date = new Date(inputTime);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        var minute = date.getMinutes();
        var second = date.getSeconds();
        minute = minute < 10 ? ('0' + minute) : minute;
        second = second < 10 ? ('0' + second) : second;
        return m + '-' + d + ' ' + h + ':' + minute;
    },
    // bb// 时间格式化2
    formatDateTime(inputTime) {
        inputTime = inputTime.replaceAll('-', '/')
        const JUST_NOW = 3000; //3s内
        const IN_SECOND = 1000 * 60; //一分钟
        const IN_MINUTE = 1000 * 60 * 60; //一小时
        const IN_HOUR = 1000 * 60 * 60 * 12; //12小时
        const IN_DAY = 1000 * 60 * 60 * 24 * 1; //1天
        const IN_MONTH = 1000 * 60 * 60 * 24 * 30; //1个月

        let localTime = new Date(); //当前系统时间
        var date = new Date(inputTime);

        if (date == null || date == undefined) {
            return;
        }
        let diff = localTime - date;
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        var minute = date.getMinutes();
        var second = date.getSeconds();
        minute = minute < 10 ? ('0' + minute) : minute;
        second = second < 10 ? ('0' + second) : second;
        var hours = date.getHours();
        var timeValue = "" + ((hours >= 12) ? "下午 " : "上午 ")
        // /
        // console.log("--y:"+y+"--m:"+m+"--d--:"+d+"--h:"+h+"--minute--:"+minute)
        var now = new Date();
        var nowy = now.getFullYear();
        var nowm = now.getMonth() + 1;
        var nowd = now.getDate();
        var nowd_z = nowd - 1;
        nowm = nowm < 10 ? ('0' + nowm) : nowm;
        nowd = nowd < 10 ? ('0' + nowd) : nowd;
        // console.log("--nowy:"+nowy+"--nowmm:"+nowm+"--d--:"+nowd )
        var returnStr = "";
        if (nowm == m && nowy == y && nowd == d) {
            // returnStr = timeValue + h + ':' + minute;
            returnStr = h + ':' + minute;
        }
        if (nowm == m && nowy == y && nowd_z == d) {
            returnStr = " 昨天  " + h + ':' + minute;
        }
        if (nowy == y && nowd_z != d && nowd != d) {
            returnStr = m + '-' + d + ' ' + h + ':' + minute;
        }
        if (nowy != y) {
            returnStr = y + '-' + m + '-' + d;
        }
        return returnStr;
    },

    filterText(content){
        var list = ['狗屎', '傻子', '操你', '十八代祖宗', '祖宗十八代', '傻B', '你妈了个', '草泥马']

        for (let i = 0; i < list.length; i++) {
            for (let j = 0; j <= content.length - list[i].length; j++) {
                //根据敏感字符个数截取str的字符进行判断
                if (list[i] == content.slice(j, j + list[i].length)) {
                    return true
                }
            }
        }
        return false
    }

}
