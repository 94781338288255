module.exports={
    title:'合约量化机器人，安全便捷高效让交易更安心。',
    ul_li1:'联系客服',
    ul_li2:'帮助中心',
    ul_li2_phone:'帮助中心',
    ul_li3:'OKX注册地址',
    ul_li4:'服务条款',
    ul_li4_phone:'服务条款',
    download_app: '下载App',
    send: '发送'
}
