module.exports ={
    account_login: 'Account login',
    login_tips: 'Welcome back! Sign in with your email, phone number or QR code',
    account: 'Account',
    password: 'Password',
    login: 'Login',
    save_pwd: 'Save password',
    forget_pwd: 'Forget Password',
    register: 'Register',
    input_phone_email: 'Please enter your email or phone number',
    input_pwd: 'Please enter the password',
    input_phone: 'Please enter the phone number',
    input_email: 'Please enter an email address',
    input_code: 'Please enter the authCode',
    sure_login_info: 'Please make sure the login information is correct!',
    login_success: 'Login successful',
    open_incognito_mode: 'You have turned on the incognito mode, during which your account and password will not be stored',

    <!-- 注册 -->
    create_felton_account: 'Create felton account',
    email: 'Email',
    mobile_phone: 'Mobile Phone',
    input_email_number: 'Please enter a email address',
    send_code: 'Send authCode',
    resend_code: 'Resend verification code',
    input_invitation_code: 'Please enter the invitation code',
    I_have_read_and_agree: 'I have read and agree the',
    terms_of_service_of_felton: 'Terms of service of Felton',
    register_save: 'Register',
    registered: 'Registered？',
    input_area_code: 'Please enter the area code',
    input_check_code: 'Please enter the check code',
    read_terms_of_service: 'Please read the terms of service',
    confirm_whether_registrationinfo_correct: 'Please confirm whether the registration information is correct！',
    regist_success: 'Registration succeeded',
    confirm_whether_phone_format_area_correct: 'Please confirm whether the phone number format or area code is correct！',
    code_sended_phone: 'The verification code has been sent to your phone！',
    input_right_email: 'Please enter the correct email',
    code_sended_email: 'The verification code has been sent to your email！',
    recommender_ID: 'Recommender ID',
    optional: 'optional',
    authCode: 'authCode',

    <!-- 忘记密码页面 -->
    home_page: 'Home',
    back_homepage: 'Back Homepage',
    reset_password: 'Reset Password',
    input_new_pwd: 'Please enter a new password',
    next_step: 'Reset',
    confirm_entered_info_is_correct: 'Please confirm whether the entered information is correct!',
    password_setting_succeeded: 'Set successfully, please login! ',
    mobile_already_exists: 'Mobile number already exists',
    email_already_exists: 'Email already exists'

}
