module.exports = {
    my_property:'我的资产',
    property_total:'资产总折合',
    capital_account:'资金账户',
    withdraw:'提现',
    withdraw1:'提现中',
    transfer:'划转',
    trading_account:'燃料账户',
    trading_account_card: '燃料/点卡账户',
    top_up:'充值',
    // rebate_account:'返佣奖金',
    rebate_account:'点卡',
    top_up_order:'充值订单',
    withdraw_order:'提现订单',
    time:'时间',
    type:'类型',
    account:'账户',
    kind:'币种',
    quantity:'数量',
    address:'地址',
    schedule:'进度',
    state:'状态',
    top_up_success:'充值成功',
    withdraw_success:'提现成功',
    underway:'进行中',
    repeal_withdraw:'撤销提现',
    record:'记录',
    record_time:'记录时间',
    account_type:'账单类型',
    transaction:'交易类型',
    fee:'扣费',
    property_change:'资产变更',
    account_balance:'账户余额',
    user_fee:'燃料扣费',
    card_fee:'点卡扣费',
    award_money:'花费金额',
    billing_profit:'开单利润',
    change_address:'变更地址/项目',
    tip:'仅显示7天内的充值/提现订单记录',
    my_capital:'我的资金',
    capital_ratio:'资金比例',
    invite_code:'邀请码',
    content: '内容',
    combo: '套餐',
    deduct: '扣除'
}
