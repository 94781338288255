module.exports = {
	Register_account: '注册平台新账户',
	User_registration: '1、用户注册',
	regist_tip: '用户注册支持用户用手机号或者邮箱注册，注册页面需要填写的信息基本相同。内容都是必填项，按要求填写后点击Register按钮即可注册成功。',
	User_login: '2、用户登录',
	login_tip: '已注册用户默认进入登录页面，填写用户名和密码即可登录，新用户也可以点击注册 进行注册。',
	Bind_Exchange_API: '绑定交易所API',
	bind_api_tip1: '1、进入我的页面 > 点击交易所API授权 > 选择要绑定的交易所，点击复制后到浏览器打开交易所注册链接。',
	bind_api_tip2: '2、在浏览器打开的交易所创建API Key(如已创建，请忽略此步)',
	okx_api_tip: '1）Okx 创建API Key（重要参数勾选）',
	gate_api_tip: '3）Gateio创建API Key（重要参数勾选）',
	bind_api_tip3: '3、打开UPlus APP绑定使用的交易所API,输入API生成的接口信息（可以配置查询、交易、提现等权限）',
	binance_api_tip: '2）Binance 创建API Key（重要参数勾选）',
	binance_api_tip1: '① 点击图中创建api，完成安全验证后进行下一步操作。',
	binance_api_tip2: '② 按照下图勾出的重要参数勾选API可用权限（请注意，交易权限仅激活90天，到期后需要重新激活）。',
	binance_api_tip3: '③ 得到API Key后请先复制到其他地方保存以便后续使用，如遇密码加密，请先删除再重新创建。',
	okx_open_account_contract: '开通账户合约量化权限',
	okx_open_account_contract1: '1、打开欧易APP，点击中间“交易”',
	okx_open_account_contract2: '2、在交易页面点击左上角图标“',
	okx_open_account_contract3: '3、找到“交易设置”，点击“交易设置”',
	okx_open_account_contract4: '4、找到“账户模式”点击“账户模式”修改为“单币种保证金模式”点击“设置”即会跳转到答题页面',
	okx_open_account_contract5: '5、通过答题后即可升级单币种保证金模式，即为设置成功。',
	quotation_marks: '”',
	next: '下一步',
	recharge_fuel_fee: '充值燃料费',
	recharge_fuel_fee1: '1、进入我的页面 > 点击总资产或者资金账户、交易账户任意位置进入我的资产详情',
	recharge_fuel_fee2: '2、点击充值即可跳到充值页面,扫描二维码进行USDT的充值',
	recharge_fuel_fee3: '3、充值完成后币是存放在资金账户,需要将资金账户的资金划转到交易账户才可以进行交易',
	recharge_fuel_fee4: '点击划转，输入转入数量，确定后即可完成划转。',
	purchase_point_card: '购买点卡',
	recharge_fuel_fee5: '1、点击我的 > 购买点卡 或者点击我的 > 钱包总资产页面 > 购买',
	recharge_fuel_fee6: '2、选择点卡套餐购买点卡。',
	start_robot: '启动机器人',
	start_robot1: '1、点击机器人页面 > 开启机器人 ，机器人处于启动状态即为开启成功',
	start_robot2: '2、添加策略',
	start_robot2_1_1: '（1）设置参数',
	start_robot2_1_2: '例如：设置投入策略的USDT金额：600 USDT',
	start_robot2_2_1: '（2）策略运行',
	start_robot2_2_2: '对冲合约交易为全自动策略，无需手动干预，',
	start_robot2_2_3: '运行中可能有浮亏，为避免产生不必要的亏损，请谨慎操作',
	start_robot2_2_4: '收益将自动划转至交易所的资金账户',
	create_policy: '去创建策略',
	go_login: '去登录',
	recharge_fuel_fee_point_card: '充值燃料费/购买点卡',
	goto_opentutorial_tip1: '如有疑问，您可以访问',
	common_problem: '常见问题',
	goto_opentutorial_tip2: '了解更多。'
}
