module.exports = {
    property_top_up:'資產充值',
    main_net:'主網',
    wallet_address:'錢包地址（此地址只可接收USDT）',
    important_tip:'重要提示',
    least_top_up_money:'最少充值金額爲',
    top_up_tip1:'請仔細確認地址，充錯無法找回',
    top_up_tip2:'大約3~5分鐘到賬',
    confirm:'確定',
    get_tip:'獲取資產充值信息失敗 !',
    copy_success:'複製成功 !',
}