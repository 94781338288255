module.exports = {
    bind_login_phone: '綁定登錄手機',
    change_login_phone: '更換登錄手機',
    your_phone: '手機號碼',
    old_phone_code: '舊手機驗證碼',
    send_code: '發送驗證碼',
    resend_code: '後重新獲取',
    new_phone: '新手機號碼',
    new_phone_code: '新手機驗證碼',
    phone_code: '手機驗證碼',
    cancel: '取 消',
    sure: '確 定',
    change_email: '更換郵箱',
    bind_email: '綁定郵箱',
    email_address: '郵箱地址',
    old_email_code: '舊郵箱驗證碼',
    new_email_address: '新郵箱地址',
    new_email_code: '新郵箱驗證碼',
    email_code: '郵箱驗證碼',
    input_old_phone: '請輸入舊手機號',
    input_code: '請輸入驗證碼',
    input_new_phone: '請輸入新手機號',
    input_old_email_address: '請輸入舊郵箱地址',
    input_old_email_code: '請輸入舊郵箱驗證碼',
    input_new_email_address: '請輸入新郵箱地址',
    input_new_email_code: '請輸入新郵箱驗證碼',
    sure_phone_format: '請確認手機號碼格式是否正確！ ',
    code_sended_phone: '驗證碼已發送到您的手機！ ',
    sure_new_email_format: '請確認新郵箱格式是否正確！ ',
    code_sended_email: '驗證碼已發送到您的郵箱！ ',
    sure_old_email_format: '請確認舊郵箱格式是否正確！ ',
    bind_email_first: '請先綁定郵箱！ ',
    input_phone_error: '手機號輸入錯誤！ ',
    change_phone_success: '更換手機號成功！ ',
    bind_phone_success: '綁定手機號成功！ ',
    input_email_error: '郵箱輸入錯誤！ ',
    change_email_success: '郵箱更換成功！ ',
    bing_email_success: '綁定郵箱成功！ ',

    <!-- 安全中心 -->
    basic_info: '基礎信息',
    phone_number: '登錄手機',
    phone_num_tip: '用於登錄、提幣、找回密碼、修改安全設置、管理API時進行驗證',
    change: '更換',
    binded: '綁定',
    unbind: '解綁',
    bind_email_address: '綁定郵箱',
    email_tip: '您可以綁定一個常用郵箱，用於登錄、找回密碼、提幣時的確認',
    login_pwd: '登錄密碼',
    pwd_tip: '用於保護賬戶安全',
    alter: '修改',
    bing_exchange: '綁定交易所',
    registered_address: '註冊地址',
    priority_display: '優先展示',
    set_priority: '設置為優先展示',
    register_address_tip: '（僅支持此鏈接註冊成功的賬戶）',
    change_pwd: '修改登錄密碼',
    old_pwd: '原登錄密碼',
    new_pwd: '新登錄密碼',
    confirm_pwd: '確認新密碼',
    change_api_info: '修改API信息',
    unbind_phone: '解綁手機',
    unbind_email: '解綁郵箱',
    input_old_pwd: '請輸入舊密碼',
    input_new_pwd: '請輸入新密碼',
    input_new_pwd_agin: '請再次輸入新密碼',
    please_enter: '請輸入',
    input_phone_code: '請輸入手機驗證碼',
    input_email_code: '請輸入郵箱驗證碼',
    input_right_info: '請輸入正確的信息！ ',
    pwd_inconsistent: '兩次密碼輸入不一致！ ',
    pwd_alter_success_to_login: '密碼修改成功,請重新登錄!',
    change_api_info_success: 'API信息修改成功！ ',
    go_to_robot_close_robot: '請先前往“機器人”頁面關閉機器人，再操作解綁API！ ',
    are_you_sure_unbind_api: '確定要解綁這條api？ ',
    unbind_success: '解綁成功',
    set_priority_success: '設置優先成功',
    input_old_code: '請輸入原手機驗證碼',
    input_new_code: '請輸入新手機驗證碼',
    copy: '複製鏈接',
    passphrase: '密碼短語',
    verify_message: '驗證信息',
}
