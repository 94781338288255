module.exports = {
    robot_title_earn:'機器人收益（僅統計2021年9月1號以後的數據）',
    today_earn:'今日收益 :',
    add_app_earn:'累計收益 :',
    add_up_rate:'累計收益率 :',
    month_average_rate:'月平均收益率 :',
    total_earn_rank :'月收益排名 :',
    no_listed:'未入榜',
    robot_earn:'機器人收益',
    total_earn_rank_title:'本月收益排行(USDT) (我的排名:',
    else_list:'其它榜單',
    earn_statistics:'收益統計',
    everyday_earn_tip:'每日0:00-0:10分進行數據結算，0:10分更新數據',
    today:'今日',
    yesterday:'昨日',
    week:'本週',
    month:'本月',
    year:'本年',
    all:'全部',
    goods_type:'策略類型',
    transaction_order_num:'成交單數',
    earn_money:'收益金額',
    earn_query:'收益査詢',
    select_month:'選擇月',
    month_earn:'月收益',
    month_earn_rate:'月收益率',
    month_earn_statistics:'月收益統計',
    earn:'收益',
    day_earn_rate:'日收益率',
    today_earning_rate: '今日收益率',
    income_month: '本月收益',
    current_month_yield: '本月收益率',
    sure: '確 定',
    hide_no_profit: '隱藏0收益策略',
    profit_detail: '收益明細',
    share_profit: '分享收益',
    robot_day_profit: '機器人今日收益',
    robot_profit_total: '纍計收益',
    add_up_rate_total: '纍計收益率',
    date: '時間',
    transaction_order: '成交(單)',
    rate_total: '收益率',
    transaction_order_of_num: '成交數量(單)',
    details: '詳情',
    see: '查看',
    data: '數據',
    daily_revenue_details: '每日收益詳情',

//    排行
    total_earn_rank_r:'累計收益排行(USDT)',
    my_rank:'我的排名',

//    share
    robot_total_earn:'機器人累計收益',
    share_title:'致力於打造安全、便捷的策略交易平臺',
    share_time:'分享時間',
    download_img:'下載圖片'
}
