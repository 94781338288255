module.exports = {
    check_email:'请输入正确的邮箱格式',
    check_phone:'请输入正确的手机号格式',
    check_password:'至少8位字符,包含1个数字,至少1个大写字符',
    not_character:'不支持特殊字符',
    decimal_two:'最多只能输入小数点后两位',
    be_about_to_pop:'即将上线,敬请期待',
    withdrawal_address_incorrect:'提现地址不正确 !',
    Minimum_cash_out_amount: '最小提现额度为50 USDT'
}
