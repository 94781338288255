module.exports = {
    select_trading_on:'選擇策略交易對',
    please_select_trading_on:'請選擇策略交易對 !',
    please_select:'请选择',
    select_strategy_type:'選擇策略類型',
    please_select_strategy_type:'請選擇策略類型 !',
    param_detail:'參數詳情',
    fold:'折叠',
    unfold:'展开',
    not_advice:'如果不會設置建議使用默認參數',
    recover_default:'恢復默認參數',

    full_order_money:'滿單倉位',
    trendPeriod:'趨勢週期',
    please_select_trendPeriod:'請選擇趨勢週期 !',
    firstOrderMoney:'首單額度',
    please_input_firstOrderMoney:'請輸入首單額度 !',
    please_input_firstOrderMoney_error:'首单额度输入错误 !',
    cycleIndex:'循環次數',
    please_input_cycleIndex:'請輸入循環次數 !',
    firstOrderTimes:'首單倍數',
    please_input_firstOrderTimes:'請輸入首單倍數 !',
    orderNumber:'補倉次數',
    please_input_orderNumber:'請輸入補倉次數 !',
    times:'次',
    stopProfitRatio:'止盈比例',
    please_input_stopProfitRatio:'請輸入止盈比例 !',
    stopProfitCallback:'追踪止盈回調',
    please_input_stopProfitCallback:'請輸入追踪止盈回調 !',
    stopProfitCallback_tip:'请先设置止盈回调再关闭 !',
    stopProfitCallback_tip2:'追踪止盈回調不建議大於止盈比例',
    coverConfig:'補倉配置',
    coverCallback:'追踪補倉回調',
    fullOrderStopLoss:'滿單止損',
    stopLossRatio:'止損比例(基于尾单价格)',
    please_input_stopLossRatio:'請輸入止損比例 !',
    full_order_money_exceed_limit:'满单仓位超出限制 !',

    strategy_success1:'生成策略成功',
    strategy_success2:'重启策略成功',
    strategy_success3:'修改策略成功',

    closed:'已關閉',
    have_set:'已設置',
    not_have_set:'未設置',

    close:'关闭',
    open:'开启',
    limited:'有限',
    infinite:'無限',
    cancel:'取消',
    confirm:'确定',
    minute:'分鐘',

    alter_next_effect:'修改后下次循环开始生效',
    alter_dialog_tip:'修改<span style="font-weight: 600;color: red"> 补仓次数 </span>后，補倉配置和追踪補倉回調将恢复至默认参数',
    least_deal:'最少交易额度是',



    //tip
    not_input_decimals:'不能輸入小數 !',
    cover_times_not_greater:'補倉次數不能大於',
    tip2:'首單倍數必須大於等於',
    tip3:'輸入參數不能為',
    tip4:'止損比例必須小於'
}