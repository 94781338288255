module.exports = {
        trading_start: 'Currently only supports UB trading, that is, buying and selling other virtual currencies in USDT, and earning USDT through strategy operation',
        policyTypeValue: '<span style="font-weight: bold"> Position Martin Strategy: </span> Lower the average position price by investing multiple positions. The advantage is that because the average price is very low, it is easy to reach the take profit point in the volatile market to achieve profit. The disadvantage is that in a trending market, the final number of positions will be very large. If the floating loss exceeds the stop loss point, the loss amount is also larger.</br>' +
            '<span style="font-weight: bold">Profit Expectation Martin Strategy: </span>Fix the position and double the profit forecast each time you cover the position. The advantage is that it does not require much principal and can accommodate small investors. The disadvantage is that in a trending market, the final take profit setting will be very large, resulting in no chance to close the position.</br>' +
            '<span style="font-weight: bold">Position & Profit Expectation Double Martin Strategy:</span>By increasing the position and profit expectation at the same time after each loss, it combines the advantages of the first two and avoids the disadvantage. It does not require a high investment of principal, and it is easier to close the position.',
        fullSpace: 'The sum of the positions from the first order to the last order, if the full order position is larger than your available assets, you can select the "Profit Expectation Martin Strategy" or "Position & Profitability" in "Select Strategy Type" with a smaller full order position Expected Double Martin Strategy", you can also modify parameters such as "First Order Amount", "Order Quantity", "Position Covering Configuration", etc.',
        trendPeriod: 'According to the trading signals of different trend cycles to capture the timing of the pending order of the first order, it will also affect the price range of the strategy\'s take profit and position cover. After closing, the order will be opened in real time', //trend cycle
        firstOrderMoney: 'The investment amount of the first order. Because Martin is a double bet, the setting of the first order determines the amount of the subsequent addition and the full position', //The amount of the first order
        cycleIndex: 'It is a cycle after taking profit selling or stop loss selling. After all the cycles are completed, the strategy will automatically stop. You can manually open the strategy and run it again', //Number of cycles
        firstOrderTimes: 'Sell if the price rises to the take profit point after the first order is purchased, and this cycle is completed. In order to avoid that the profit amount is too small due to the small position of the first order, or the amount of the first order is too large and the position of the full order is too high, the first order multiple can be set separately, and the multiple can be set arbitrarily', //The first order multiple
        orderNumber: 'The total number of positions added, excluding the first order', //Number of orders
        stopProfitRatio: 'calculated based on the average price of the total position, if the profit reaches the take profit ratio, you can sell or start the take profit callback mechanism', // take profit ratio
        stopProfitCallback: 'When trailing take profit, the price is adjusted back to the "take profit callback" ratio, that is, the market price pending order is sold. It may be impossible to sell because the price callback is too fast', //Take profit callback
        coverConfig: 'Based on the previous order, what percentage of each time the decrease reaches to cover the position, and the multiple of the position to cover', // configuration of covering positions
        coverCallback: 'When tracking the cover position, the price rises to the "cover position callback" ratio, that is, the market price pending order increases the position, which may be unable to buy because the price rises too fast', //cover position callback
        fullOrderStopLoss: 'The risk control mechanism of the strategy will sell a limit order at the current market price according to the stop loss ratio', //stop loss on full order
        stopLossRatio: 'When the last order is closed, the "stop loss ratio" that falls more than the last order price is to sell the full position at the current market price', //Stop loss ratio
}