module.exports = {
        trading_start:'目前仅支持UB交易，即以USDT买卖其他虚拟货币，通过策略运行赚取USDT',
        policyTypeValue:'<span style="font-weight: bold">仓位马丁策略：</span> 通过倍投仓位拉低仓位均价。优势是因为均价很低，所以容易在波动的行情中达到止盈点实现盈利。劣势是是在趋势行情中，最终的建仓数量会非常大。如果浮动亏损超过止损点，损失金额也较大。</br>' +
            '<span style="font-weight: bold">盈利预期马丁策略：</span>把仓位固定，每次补仓把盈利预期翻倍。优势是不需要太多本金，可以包容小额的投资者。劣势是在趋势行情中，最终的止盈设置会非常大，导致没有机会平仓。</br>' +
            '<span style="font-weight: bold">仓位&盈利预期双马丁策略：</span>通过每次亏损后把仓位和盈利预期同时增加的方式，综合了前两种的优势，规避了劣势。既不需要高额的本金投入，又能比较容易平仓。',
        fullSpace:'首单到最后一单的仓位之和，如果满单仓位大于自己的可用资产，可在“选择策略类型”中选择满单仓位较小的“盈利预期马丁策略”或“仓位&盈利预期双马丁策略”、也可以修改“首单额度”、“做单数量”、“补仓配置”等参数',
        trendPeriod:'根据不同趋势周期的交易信号捕捉首单的挂单时机，也会影响策略的止盈及补仓的价格幅度，关闭后则实时开单',     //趋势周期
        firstOrderMoney:'首单的投入金额。因为马丁是倍投，所以首单的设置决定了后边加仓的金额以及满单仓位',  //首单额度
        cycleIndex:'止盈卖出或止损卖出后为一次循环，全部循环次数完成后策略自动停止，可手动开启策略重新再运行一次',    //循环次数
        firstOrderTimes:'如果首单买入后价格上涨达到止盈点则卖出，本次循环完成。为避免因首单仓位过小导致盈利金额过小，或首单金额过大满单仓位过高，可单独设置首单倍数，倍数可以任意设置',  //首单倍数
        orderNumber:'总共加仓的次数，不包含首单',      //做单数量
        stopProfitRatio:'以总仓位的均价计算，盈利达到止盈比例即可以卖出或启动止盈回调机制',   //止盈比例
        stopProfitCallback:'追踪止盈时价格回调到“止盈回调”比例即市价挂单卖出，可能会因为价格回调过快导致无法卖出',   //止盈回调
        coverConfig:'基于上一单，每次跌幅达到多少比例进行补仓，及补仓的倍数',           //补仓配置
        coverCallback:'追踪补仓时，价格上升到“补仓回调”比例即市价挂单加仓，可能会因为价格上涨过快导致无法买入',         //补仓回调
        fullOrderStopLoss:'策略的风控机制，将按止损比例以当前的市价挂限价单卖出', //满单止损
        stopLossRatio:'当最后一单成交后，跌幅超过尾单价格的“止损比例”是，将满仓以当前的市价挂现价单卖出止损',        //止损比例
}