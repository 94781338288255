import Vue from 'vue'
import VueRouter from 'vue-router'

/**
 * 解决重复点击路由报错
 * @type {{(location: RawLocation): Promise<Route>, (location: RawLocation, onComplete?: Function, onAbort?: ErrorHandler): void}}
 */
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/home/Home_new')
    },
    // {
    //   path: '/new',

    //   name: 'New',
    //   component: ()=>import('../views/home/Home_new')
    // },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login/Login')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/register/Register')
    },
    {
        path: '/forget_password',
        name: 'Forget_password',
        component: () => import('@/views/forget_password/Forget_password')
    },
    {
        path: '/center',
        component: () => import('@/components/Center'),
        redirect: '/user_center',
        meta: {
            keepAlive: true
        },
        children: [
            {
                path: '/user_center',
                name: 'user_center',
                component: () => import('@/views/user_center/User_center'),
                meta: {
                    name: '/user_center',
                    requireAuth: true
                }
            },
            {
                path: '/profit_details',
                name: 'profit_details',
                component: () => import('@/views/user_center/profit_details'),
                meta: {
                    name: '/user_center',
                    requireAuth: true
                }
            },
            {
                path: '/profit_details_every',
                name: 'profit_details_everyday',
                component: () => import('@/views/user_center/profit_details_everyday'),
                meta: {
                    name: '/user_center',
                    requireAuth: true
                }
            },
            {
                path: '/earn_rank',
                name: 'earn_rank',
                component: () => import('@/views/user_center/earn_rank'),
                meta: {
                    name: '/earn_rank',
                    requireAuth: true
                }
            },
            {
                path: '/rebate',
                name: 'rebate',
                component: () => import('@/views/rebate/Rebate'),
                meta: {
                    name: '/rebate',
                    requireAuth: true
                }
            },
            {
                path: '/robot',
                name: 'robot',
                component: () => import('@/views/robot/Robot'),
                meta: {
                    name: '/robot',
                    requireAuth: true,
                    keepAlive: true
                }
            },
            {
                path: '/week_list',
                name: 'week_list',
                component: () => import('@/views/robot/thisWeekList'),
                meta: {
                    name: '/robot',
                    requireAuth: true
                }
            },
            {
                path: '/robot/auto_detail',
                name: 'auto_detail',
                component: () => import('@/views/robot/Robot_auto_detail'),
                meta: {
                    name: '/robot',
                    requireAuth: true
                }
            },
            {
                path: '/robot/contract_detail',
                name: 'contract_detail',
                component: () => import('@/views/robot/Robot_contract_detail'),
                meta: {
                    name: '/robot',
                    requireAuth: true
                }
            },
            {
                path: '/robot/term_detail',
                name: 'term_detail',
                component: () => import('@/views/robot/Robot_term_detail'),
                meta: {
                    name: '/robot',
                    requireAuth: true
                }
            },
            {
                path: '/robot/semi_detail',
                name: 'semi_detail',
                component: () => import('@/views/robot/Robot_semi_detail'),
                meta: {
                    name: '/robot',
                    requireAuth: true
                }
            },
            {
                path: '/robot/martin_detail',
                name: 'martin_detail',
                component: () => import('@/views/robot/Robot_martin_detail'),
                meta: {
                    name: '/robot',
                    requireAuth: true
                }
            },
            {
                path: '/robot/martin_add',
                name: 'martin_add',
                component: () => import('../views/robot/martin/Martin_add_sm'),
                meta: {
                    name: '/robot',
                    requireAuth: true
                }
            },
            {
                path: '/user',
                name: 'user',
                component: () => import('@/views/user/User'),
                meta: {
                    name: '/user',
                    requireAuth: true
                },
                redirect: '/user/property',
                children: [
                    {
                        path: 'safe',
                        name: 'safe',
                        component: () => import('@/views/user/Safe'),
                        meta: {
                            name: '/user',
                            tabName: '/user/safe',
                            requireAuth: true
                        }
                    },
                    {
                        path: 'apiPage',
                        name: 'apiPage',
                        component: () => import('@/views/user/ApiPage'),
                        meta: {
                            name: '/user',
                            tabName: '/user/ApiPage',
                            requireAuth: true
                        }
                    },
                    {
                        path: 'member',
                        name: 'member',
                        component: () => import('@/views/user/Member'),
                        meta: {
                            name: '/user',
                            tabName: '/user/member',
                            requireAuth: true
                        }
                    },
                    {
                        path: 'personal',
                        name: 'personal',
                        component: () => import('@/views/user/Personal'),
                        meta: {
                            name: '/user',
                            tabName: '/user/personal',
                            requireAuth: true
                        }
                    },
                    {
                        path: 'property',
                        name: 'property',
                        component: () => import('@/views/user/Property'),
                        meta: {
                            name: '/user',
                            tabName: '/user/property',
                            requireAuth: true
                        }
                    },
                ]
            },

            {
                path: '/middle',
                name: 'middle',
                redirect: '/safe',
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/exit',
                name: 'exit',
                component: () => import('@/views/rebate/Rebate'),
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/feedBack',
                name: 'feedBack',
                component: () => import('@/components/FeedBack'),
                meta: {
                    requireAuth: true
                }
            },


        ]
    },
    {
        path: '/messageBack',
        name: 'messageBack',
        component: () => import('@/components/messageBack'),
        meta: {
            // requireAuth: true
        }
    },
    {
        path: '/robot/UserUsageProtocol',
        name: 'UserUsageProtocol',
        component: () => import('@/components/UserUsageProtocol'),
        // meta:{
        //   requireAuth:true
        // }
    },
    {
        path: '/transactionAgreement',
        name: 'transactionAgreement',
        component: () => import('@/components/HedgingContractTransactionAgreement'),
        // meta:{
        //   requireAuth:true
        // }
    },
    {
        path: '/grant_member',
        name: 'grant_member',
        component: () => import('../views/grant_member/Member'),
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/member_service_agreement',
        name: 'member_service_agreement',
        component: () => import('../views/grant_member/member_content/member_service_agreement'),
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/help',
        component: () => import('../views/help_center/home_help'),
        redirect: '/helpCenter',
        children: [
            {
                path: '/helpCenter',
                name: 'helpCenter',
                component: () => import('../views/help_center/help_content_one'),
                meta: {
                    name: '/help',
                    keepAlive: true
                },
            },
            {
                path: '/helpTitle',
                name: 'helpTitle',
                component: () => import('../views/help_center/help_content_two'),
                meta: {
                    name: '/help',
                    keepAlive: true
                },
            },
            // {
            //     path: '/helpContent',
            //     name: 'helpContent',
            //     component: () => import('../views/help_center/help_content_three'),
            //     meta: {
            //         name: '/help',
            //     },
            // },
            {
                path: '/helpNoticeList',
                name: 'helpCenter',
                component: () => import('../views/help_center/help_notice_list'),
                meta: {
                    name: '/help',
                    keepAlive: true
                },
            },
            {
                path: '/helpNoticeAll',
                name: 'helpCenter',
                component: () => import('../views/help_center/help_notice_all'),
                meta: {
                    name: '/help',
                    keepAlive: true
                },
            },
            {
                path: '/helpNoticeContent',
                name: 'helpCenter',
                component: () => import('../views/help_center/help_notice_content'),
                meta: {
                    name: '/help',
                    keepAlive: true
                },
            },
            {
                path: '/helpCommonProblem',
                name: 'helpCenter',
                component: () => import('../views/help_center/help_common_problem'),
                meta: {
                    name: '/help',
                    keepAlive: true
                },
            },
            {
                path: '/helpOpenTutorial',
                name: 'helpCenter',
                component: () => import('../views/help_center/help_open_tutorial'),
                meta: {
                    name: '/help',
                    keepAlive: true
                },
            },
        ]
    },
    {
        path: '/download',
        name: 'download',
        component: () => import('@/views/user/download_app'),
        meta: {
            // requireAuth: true
        }
    },
    {
        path: '/downloadPg',
        name: 'downloadPg',
        component: () => import('../views/downloadPg/downloadPg'),
        meta: {
            // requireAuth: true
        }
    },

    {
        path: '*',
        component: () => import('@/views/Not_found')
    },
    {
        path: '/*/*',
        component: () => import('@/views/Not_found')
    },
    {
        path: '/Server_error',
        component: () => import('@/views/Server_error')
    },
    // {
    //     path: '/webview_robot',
    //     name: 'robot',
    //     component: () => import('../views/webview/Robot'),
    // },
    // {
    //     path: '/webview_week_list',
    //     name: 'webview_week_list',
    //     component: () => import('../views/webview/thisWeekList'),
    //     meta: {
    //         name: '/robot',
    //         requireAuth: true
    //     }
    // },
    // {
    //     path: '/webview_robot/auto_detail',
    //     name: 'webview_auto_detail',
    //     component: () => import('../views/webview/Robot_auto_detail'),
    //     meta: {
    //         name: '/robot',
    //         requireAuth: true
    //     }
    // },
    // {
    //     path: '/webview_robot/contract_detail',
    //     name: 'webview_auto_detail',
    //     component: () => import('../views/webview/Robot_contract_detail'),
    //     meta: {
    //         name: '/robot',
    //         requireAuth: true
    //     }
    // },
    // {
    //     path: '/webview_robot/semi_detail',
    //     name: 'webview_semi_detail',
    //     component: () => import('../views/webview/Robot_semi_detail'),
    //     meta: {
    //         name: '/robot',
    //         requireAuth: true
    //     }
    // },
    // {
    //     path: '/webview_robot/martin_detail',
    //     name: 'webview_martin_detail',
    //     component: () => import('../views/webview/Robot_martin_detail'),
    //     meta: {
    //         name: '/robot',
    //         requireAuth: true
    //     }
    // },
    // {
    //     path: '/webview_robot/martin_add',
    //     name: 'webview_martin_add',
    //     component: () => import('../views/webview/martin/Martin_add_sm'),
    //     meta: {
    //         name: '/robot',
    //         requireAuth: true
    //     }
    // },
    // {
    //     path: '/webview/messageBack',
    //     name: 'messageBack',
    //     component: () => import('@/components/messageBack'),
    //     meta: {
    //         requireAuth: true
    //     }
    // },
]

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})
router.beforeEach((to, from, next) => {
    if (window._hmt) {
        if (to.path) {
            window._hmt.push(['_trackPageview', '/#' + to.fullPath])
        }
    }

    if (to.meta.requireAuth) {
        if (!localStorage.getItem('token')) {
            // localStorage.removeItem('oldPath')
            // localStorage.setItem('oldPath',to.path)
            next('/login')
        } else {
            next()
        }
    } else {
        next()
    }
})
export default router
