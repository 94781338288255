module.exports = {
        trading_start:'目前僅支持UB交易，即以USDT買賣其他虛擬貨幣，通過策略運行賺取USDT',
        policyTypeValue:'<span style="font-weight: bold">倉位馬丁策略：</span> 通過倍投倉位拉低倉位均價。優勢是因爲均價很低，所以容易在波動的行情中達到止盈點實現盈利。劣勢是是在趨勢行情中，最終的建倉數量會非常大。如果浮動虧損超過止損點，損失金額也較大。</br>' +
            '<span style="font-weight: bold">盈利預期馬丁策略：</span>把倉位固定，每次補倉把盈利預期翻倍。優勢是不需要太多本金，可以包容小額的投資者。劣勢是在趨勢行情中，最終的止盈設置會非常大，導致沒有機會平倉。</br>' +
            '<span style="font-weight: bold">倉位&盈利預期雙馬丁策略：</span>通過每次虧損後把倉位和盈利預期同時增加的方式，綜合了前兩種的優勢，規避了劣勢。既不需要高額的本金投入，又能比較容易平倉。',
        fullSpace:'首單到最後一單的倉位之和，如果滿單倉位大於自己的可用資產，可在“選擇策略類型”中選擇滿單倉位較小的“盈利預期馬丁策略”或“倉位&盈利預期雙馬丁策略”、也可以修改“首單額度”、“做單數量”、“補倉配置”等參數',
        trendPeriod:'根據不同趨勢週期的交易信號捕捉首單的掛單時機，也會影響策略的止盈及補倉的價格幅度，關閉後則實時開單',     //趨勢週期
        firstOrderMoney:'首單的投入金額。因爲馬丁是倍投，所以首單的設置決定了後邊加倉的金額以及滿單倉位',  //首單額度
        cycleIndex:'止盈賣出或止損賣出後爲一次循環，全部循環次數完成後策略自動停止，可手動開啓策略重新再運行一次',    //循環次數
        firstOrderTimes:'如果首單買入後價格上漲達到止盈點則賣出，本次循環完成。爲避免因首單倉位過小導致盈利金額過小，或首單金額過大滿單倉位過高，可單獨設置首單倍數，倍數可以任意設置',  //首單倍數
        orderNumber:'总共加仓的次数，不包含首单',      //做單數量
        stopProfitRatio:'以總倉位的均價計算，盈利達到止盈比例即可以賣出或啓動止盈回調機制',   //止盈比例
        stopProfitCallback:'追蹤止盈時價格回調到“止盈回調”比例即市價掛單賣出，可能會因爲價格回調過快導致無法賣出',   //止盈回調
        coverConfig:'基於上一單，每次跌幅達到多少比例進行補倉，及補倉的倍數',           //補倉配置
        coverCallback:'追蹤補倉時，價格上升到“補倉回調”比例即市價掛單加倉，可能會因爲價格上漲過快導致無法買入',         //補倉回調
        fullOrderStopLoss:'策略的風控機制，將按止損比例以當前的市價掛限價單賣出', //滿單止損
        stopLossRatio:'當最後一單成交後，跌幅超過尾單價格的“止損比例”是，將滿倉以當前的市價掛現價單賣出止損',        //止損比例
}