module.exports={
    felton_quantitative_title: 'Felton量化交易机器人',
    felton_quantitative_content1: '基于大数据和人工智能的虚拟货币交易机器人',
    felton_quantitative_content2: '智能构建 让您躺着赚钱',
    start_robot: '启动机器人',
    product_description: '产品介绍',

    golden_triangle: '黄金三角交易',
    stable_income: '收益稳定',
    introduce: '介绍',
    golden_triangle_tip: '三个币种循环交易，活跃度高',
    stability: '稳定性',
    rate_return: '收益率',
    capture_big_market: '捕捉大行情的能力',

    bb_transaction: 'BB交易',
    long_term_profit: '长期收益',
    bb_transaction_tip: '任意两个币种交易，适合横盘屯币',

    ub_transaction: 'UB交易',
    high_return: '高回报',
    ub_transaction_tip: '用USDT交易货币，获得长期理财收益',

    grid_strategy: '网格策略',
    volatility_arbitrage: '震荡套利',
    grid_strategy_tip: '震荡行情自动低买高卖，稳定套利',

    martin_strategy: '马丁策略',
    money_management: '仓位管理',
    martin_strategy_tip: '单边加倍押注拉低成本，止盈回调',

    contract_strategy: '对冲合约交易',
    stay_tuned_for: '收益率高',
    coming_soon: '合约多空对冲亏损，震荡套利，超高收益',

    investment_advice: '产品优势',
    reference_plan: '参考建议',
    product_portfolio : '产品介绍',
    membership_level: '会员等级',
    for_people: '适合人群',
    features: '特点',
    monthly_return: '月收益率',
    turn_on: '创建',
    option_one: '选项一',
    option_one_introduce: '2个“黄金三角交易”\n+\n2个“BB”交易',
    option_one_for_people: '囤币的比特币信仰者',
    option_one_features: '多币种交易，开单量大，收益长期稳定',
    option_two: '选项二',
    option_two_introduce: '1个“黄金三角交易”\n+\n5个“BB”交易',
    option_two_for_people: '囤币的比特币信仰者',
    option_two_features: '适合在平盘交易中获利，不适合剧烈的市场波动',
    option_three: '选项三',
    option_three_introduce: '2个“UB交易”\n+\n1个“BB交易”',
    option_three_for_people: '获得长期理财收益',
    option_three_features: '适合在市场剧烈波动时赚取收益，短期内可能有30%-50%的浮亏',
    no_suitable_solution: '没有合适的解决方案，试试自定义',
    custom_solution: '解决方案？',

    product_advantages: '产品优势',
    exclusive_strategy_model: '独家策略模型',
    exclusive_strategy_model_tip: '智能交易算法和精细化仓位管理，强大的风控系统和灵活的点位捕捉',
    fully_automatic_unattended: '全自动无人值守',
    fully_automatic_unattended_tip: '傻瓜式代理投资，降低时间成本，弥补信息不对称，轻松享受睡后收益',
    financial_security: '资金安全保障',
    financial_security_tip: '支持API接入Binance、Okx等各大交易所，仅调用交易权限，数字资产始终在自己的交易所账户中',
    regularly_update_policy: '定期更新策略',
    regularly_update_policy_tip: '强大的技术策略团队，根据市场变化，定期调整参数、更新策略',

    opening_process: '开通流程',
    choose_exchange_connect: '选择一个交易所并连接',
    recharge_fuel: '充值燃料费'

}
