module.exports={
	"product_info_title": "UPlus产品介绍",
	"product_info": "我们的产品就属于量化策略交易，全自动交易，不需要设置，不需要手动开平仓，将交易所API绑定到我们平台，授权交易权限就可以了。本金收益全在交易所，安全无风险。软件免费使用，收益时扣取利润分成，扣取每笔盈利的25%！我们没有直接扣取利润的权限，所以需要会员提前在APP充值一些USDT。充值的USDT可以用来充当燃料或是购买点卡。",
	"start_robot_condition": "启动机器人需要什么条件",
	"start_robot_condition_tip": "燃料或是点卡必须要大于等于本金的10%才可以启动机器人",
	"Strategy_Instructions": "策略选择说明",
	"Strategy_Instructions_tip": "策略分为合约和现货两种，合约赚USDT，现货赚币量。合约量化考虑策略的稳定性暂时只上线了部分币种，不过交易赚取的是USDT，选哪个交易对关系不大。现货适用于全部币种！",
	"Contract_Trading_Instructions": "合约策略交易概括说明",
	"Contract_Trading_Instructions_tip": "交易策略简单理解属于多空对冲套利，通过“独家数学模型”来精准把握买进及卖出机会，从而实现精细化的仓位管理、强大的风险控制和灵活的行情捕捉。从宏观角度看，策略可适应绝大部分行情，通过在大部分行情里的高收益覆盖小概率行情中的亏损，以保证长期运行中的高收益率。",
	"Modify_position_description": "修改策略仓位说明",
	"Modify_position_description_tip": "当前修改仓位只可以增加不能减少。在当前策略找到修改策略，点击修改策略，输入新的USDT金额，点击确认修改即可！。修改后新仓位可能不会立即生效，而是保证策略正常运行的情况下逐步更新完成。",
	"Stop_Loss_Instructions": "止损说明",
	"Stop_Loss_Instructions_tip_title1": "策略设置不止损：",
	"Stop_Loss_Instructions_tip_content1": "想要扛单至行情回调可选择不止损。策略使用的是全仓保证金，可能会亏掉交易账户仓位设置以外的保证金，需留意风险。",
	"Stop_Loss_Instructions_tip_title2": "策略设置止损：",
	"Stop_Loss_Instructions_tip_content2": "1.策略风险较高时会平掉部分仓位以释放保证金抵御风险，平掉仓位的浮亏会转为实亏。后续盈利优先补亏损，补亏损不消耗燃料费及点卡；\n2.机器人会实时监控策略的风险水平，当风险较高时会自动止损，可能会触发多次止损，最大止损总仓位的75%。",
	"Card_Fuel_Instructions": "点卡和燃料费说明",
	"Card_Fuel_Instructions_tip": "点卡跟燃料都是用来扣取利润分成的。收益时扣取点卡或是燃料费来抵扣利润。区别在于购买点卡时有折扣，但如果有剩余是不能提走，而燃料没有折扣有剩余可以提走!",
	"Trading_account_loss": "交易账户浮亏说明",
	"Trading_account_loss_tip": "多空双开的这种交易逻辑使得交易账户时而浮亏时而浮盈。这属于正常。浮亏不能算做亏损的，如果是产生了实际的止损，会记录亏损，之后每笔交易产生的利润的70%会优先弥补亏损（在补亏损的过程中这部分利润不扣取分成），直到亏损弥补完，剩余利润的30%仍然会扣取分成！",
	"Withdrawal_Instructions": "提现说明",
	"Withdrawal_Instructions_tip": "提现需要人工审核，24小时以内到账，节假日顺延！",
	"Exclusive_user_introduction": "专属用户介绍",
	"Exclusive_user_introduction_tip1": "Okx专属用户：通过OKX专属链接",
	"Exclusive_user_introduction_tip2": "或邀请码（UPLUS3）注册的交易所会员。\n币安专属用户：申请币安API，通过UPlus平台校验并绑定成功的用户。\n专属用户权益：专属用户享受限时98%燃料费折扣。",
	"Deduction_Instructions": "扣费说明",
	"Deduction_Instructions_tip": "软件免费使用，收益时扣取利润分成，扣取每笔盈利的25%！我们没有直接扣取利润的权限，所以需要会员提前在APP充值一些USDT。充值的USDT可以用来充当燃料或是购买点卡。点卡跟燃料都是用来扣取利润分成的！",
	"must_read_newbies": "新手必读",
	"must_read_newbies_content1_1": "1.合约交易策略属于高频对冲交易，不判断行情，不猜涨跌，通过价格的波动持续套利，所以本金可能会有一定的浮动，一般在20%以内，浮动多少跟行情相关，持续运行本金会慢慢变多，新手能接受再开启。",
	"must_read_newbies_content1_2": "切勿经常手动操作会影响机器人运行导致收益降低还会增大风险。",
	"must_read_newbies_content1_3": "另外购买点卡后不能退款，所以决定长期使用后再购买点卡，可以先充值到燃料账户启动运行。",
	"must_read_newbies_content2_0": "2.请谨慎在交易所手动挪用资金，",
	"must_read_newbies_content2": "划转后会导致机器人交易异常，无法正常开单，严重情况会有爆仓风险。",
	"must_read_newbies_content3": "3.机器人累计仓位是复杂和缓慢的，系统会自动在较长周期内布局，对利润有精确的计算，新手不要频繁删除机器人重新添加，没有浮亏不代表已经将盈利全部结算，仓位就是未来的利润，没有仓位就没有利润。当行情来的时候失去了更多盈利的仓位利润就会降低许多。"
}
