module.exports = {
    personal_data:'個人資料',
    avatar:'頭像',
    nickname:'暱稱',
    passport:'護照號碼',
    birth_date:'出生日期',
    not_set:'暫未設置',
    please_input:'请输入',
    my_email:'我的郵箱',
    my_phone:'我的手机',
    not_bind_phone:'暫未綁定手機',
    not_bind_email:'暫未綁定郵箱',
    change:'更換',
    bind:'绑定',
    click_upload:'點擊上傳',
    upload_tip:'只能上傳jpg/png文件，且不超過12MB',
    select_date:'选择日期',
    alter_nickname:'修改暱稱',
    cancel:'取 消',
    confirm:'确 定',
    alter_passport:'修改護照',
    select_y_t_d:'选择年月日',
    please_input_nickname:'請輸入暱稱 !',
    please_input_passport:'請輸入護照 !',
    nonsupport:'不支持特殊字符 !',
    alter_success:'修改成功 !',
    avatar_upload_success:'頭像上傳成功 !',

    img_err1:'图片只能是 JPG或者PNG 格式!',
    img_err2:'图片大小不能超过 12MB!'
}