module.exports = {
    home_page:'Home page',
    user_center:'data',
    robot:'robot',
    // rebate:'rebate',
    rebate:'reward',
    member:'member',
    my:'Mine',
    my_property:'My property',
    my_member:'My member',
    personal_data:'personal data',
    safe:'safety settings',
    exit:'exit',
    user_center_tab1:'Robot income',
    user_center_tab2:'Daily income',
    user_center_tab3:'Revenue query',
    robot_tab1:'Robot Monitoring',
    robot_tab2:'Robot Settings',
    robot_tab3:'Robot revenue ranking',
    safe_tab1:'My Assets',
    safe_tab2:'My members',
    safe_tab3:'Profile',
    safe_tab4:'safety settings',
    sm_tab1:'Data Center',
    sm_tab2:'robot',
    // sm_tab3:'Rebate',
    sm_tab3:'Bonus',
    sm_tab4:'Security Center',
    center:'personal center',
    drawer_tab1:'Data Center',
    drawer_tab2:'Robot Center',
    drawer_tab3:'Commission Center',
    drawer_tab4:'Member Center',
    exit_login_success:'Log out successfully!',
    down_android: 'Android',
    coming_online_soon: 'iOS',
    go_back: 'back',
}
