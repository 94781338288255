module.exports={
    go_back:'Back homepage',
    member_center:'Member center',
    user_activation_code:'Use activation code',
    member_right:'Membership benefits',
    member_title:'Ultimate quantitative experience  Enjoy the feast of wealth',
    member_title_sub:'Super benefits and earning member account，Open account ',
    member_title_sub2:'Enjoy multiple services',
    renew_eight_discount:'20% off on renewals for a limited time',
    upgrade:'Upgrade to',
    please_input_member_activation_code:'Please enter the membership activation code',
    conversion:'Conversion',
    confirm_order_form:'Confirm order',
    member_privilege:'Member privilege/year',
    order_money:'Order Amount',
    property_money:'Asset Amount',
    balance_lack:'Only support asset balance is insufficient,Please first',
    top_up:'Recharge',
    support_type:'Only support asset balance payment',
    please_input_email_auth_code:'Please enter the email verification code',
    please_input_phone_auth_code:'Please enter the phone verification code',
    send_auth_code:'Send verification code',
    regain:'Reacquire after',
    confirm_open:'Confirm to open',
    property_top_up:'Asset recharge',
    main_net:'Mainnet',
    wallet_address:'Wallet address（This address can only receive USDT）',
    important_tip:'important tip',
    least_top_up_money:'The minimum top-up amount is',
    top_up_tip1:'Please confirm the address carefully, Recharge error cannot be retrieved',
    top_up_tip2:'About 3~5 minutes to the account',
    user_already_top_up:'I have recharged',
    conversion_success:'Conversion is successful, you have become',
    perpetual:'Permanent',
    i_see:'I Know',
    please_input_activation_auth_code:'Please enter the activation code',
    please_input_auth_code:'Please enter the verification code',
    perpetual_valid:'Perpetually valid',
    expire:'Expiration',
    not_yet_open:'Not yet opened',
    balance_lack_tip:'Insufficient balance !',
    sent_phone_auth_code:'Verification code has been sent to your phone !',
    sent_email_auth_code:'Verification code has been sent to your phone !',
    copy_success:'Copy successfully !',
    get_balance_error:'Failed to obtain asset recharge information !',
    demotion_buy_warn:'You are already an SVIP, you cannot downgrade to buy a VIP !',
    property_money_new: 'Fund account balance',
    balance_lack_new:'Only support asset balance is insufficient,Please first',
    support_type_new:'Only fund account balance payment is supported',

    //Member
    my_member:'My member',
}
