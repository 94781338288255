module.exports = {
    property_top_up:'资产充值',
    main_net:'主网',
    wallet_address:'钱包地址（此地址只可接收USDT）',
    important_tip:'重要提示',
    least_top_up_money:'最少充值金额为',
    top_up_tip1:'请仔细确认地址，充错无法找回',
    top_up_tip2:'大约3~5分钟到账',
    confirm:'确定',
    get_tip:'获取资产充值信息失败 !',
    copy_success:'复制成功 !',
}