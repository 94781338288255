module.exports = {
    capital_transfer:'资金划转',
    from:'从',
    to:'到',
    balance:'余额',
    please_select:'请选择',
    transfer_money:'划转金额',
    all:'全部',
    confirm_transfer:'确认划转',
    capital_account:'资金账户',
    trading_account:'燃料账户',
    // rebate_account:'返佣奖金',
    rebate_account:'奖金',
    please_input_transfer_money:'请输入划转金额 !',
    restrict_input:'最多只能输入小数点后两位 !',
    transfer_success:'划转成功 !',
    warn_not:'不能划转到返佣奖金 !',
    not_negative_numbers: '划转金额需≥0',
}
