module.exports= {
    robot: 'Robot',
    robot_dealer_name1:'Binance Robot',
    robot_dealer_name2:'Okx Robot',
    robot_monitoring:'Robot monitoring',
    robot_base_info:'Basic robot information',
    robot_name: 'Robot name',
    robot_run_state: 'state',
    closed: 'closed',
    turned_on: 'turned on',
    robot_state_name1: 'running',
    robot_state_name2: 'stopped',
    robot_state_name3: 'deleted',
    robot_state_name4: 'Service switching',
    robot_state_name5: 'Unbound API',
    dormant: 'dormant',
    robot_run_time: 'Run time',
    hour:'Hour',
    robot_asset_distribution:'Robot asset distribution',
    robot_total_assets: 'Total assets',
    welcome_to: 'Welcome to',
    robot_center: 'Robot Center',
    fee_relief: 'fuel fee relief in progress',
    expire: 'expiration',
    register_address: 'registered address',
    only_accounts_registered: 'only accounts successfully registered with this link are supported',

    <!-- child component start -->
    robot_products: 'child component start',
    auto_robot: 'Automatic robot',
    sime_robot: 'Semi-automatic',
    auto_robot_tip: 'After startup, the robot can analyze market conditions and make trading decisions by itself。',
    sime_robot_tip: 'Users need to set parameters according to the analysis and judgment of the market。',
    content1:'Description: Spot 3 semi-automatic 4 contract 3.',
    content3: 'The number of grids indicates the number of pending order cells divided in the oscillating range. For example, the interval 100-400, the equal difference, and the grid number 3 are divided into 3 grids: 100-200, 200-300, and 300-400. Equal difference: the difference between the prices of every two adjacent tiers is equal; equal ratio: the ratio of the prices of every two adjacent tiers of pending orders is equal.',
    content4: 'Grid is a trading strategy that automatically buys low and sells high within a specified price range. After the user sets the parameters, the system divides the small grid to automatically place the order. With the market fluctuation, the strategy buys low and sells high to earn band income.',
    yitianjjia: 'Added',
    haiketianjia: 'Can also add',
    golden_triangle_arbitrage_tip: 'Three currencies are traded circularly with high activity',
    bb_trade_tip: 'Any two currency transactions, suitable for sideways currency',
    ub_trade_tip: 'Use USDT to trade currencies to gain long-term financial returns',
    grid_strategy_tip: 'Automatically buy low and sell high in the volatile market, stable arbitrage',
    martin_strategy_tip: 'Double the bet on one side to lower the cost and pull back the profit',
    current_policy: 'My bots',
    historical_policy: 'History',
    paused: 'Paused',
    cycle_completed: 'Cycle completed',
    cycle_stopped: 'Cycle stopped',
    auto: 'Automatic',
    semi: 'semi-automatic',
    put_position: 'put position (piece)',
    create_time: 'create time',
    Arbitrage_times: 'Arbitrage times',
    Total_revenue: 'Total revenue',
    yield:'yield',
    Manually_stopped: 'Manually stopped',
    average_position_price: 'Average position price',
    cycled: 'cycled',
    replenishment_depth: 'replenishment depth',
    not_yet: 'Not yet',
    first_order: 'First order',
    // di: 'the first',
    di: '',
    ci: 'times',
    floating_profit_loss: 'Floating profit and loss',
    current_price: 'Current price',
    strategy_details: 'Details',
    create_strategy: 'Create',
    pause_strategy: 'Pause',
    open_strategy: 'Open strategy',
    del_strategy: 'Delete',
    stop_strategy: 'Stop',
    restart_strategy: 'Restart strategy',
    pause: 'Pause',
    stop: 'stop',
    start: 'Open',
    restart: 'restart',
    total: 'Total',
    strip: 'strip',
    del: 'Delete',
    number_of_cycles: 'Number of cycles',
    add: 'Add',
    sj_tip: 'A set of golden triangle arbitrage includes 3 trading pairs.',
    select: 'select',
    binance: 'Binance',
    okx: 'Okx',
    space_currency: 'Position currency',
    supportive: 'supported',
    transaction_pair: 'Transaction pair',
    please_select: 'Please select',
    set_transaction_pair_position: 'Set transaction pair position',
    sure: 'Confirm',
    available: 'available',
    ub_tip: 'The amount that the robot uses for this currency transaction (USDT), it is recommended that the total position should not exceed',
    available_balance: 'Available balance',
    grid_hokder: 'Please select the currency to be invested',
    grid_buy_holder: 'Please select the currency to buy',
    grid_section1: 'Enter the predicted price fluctuation range',
    grid_section2: 'For the above interval, determine the number of grids to be divided',
    grid_section3: 'Determine the amount to invest in the grid strategy',
    interval_lowest_price: 'Interval lowest price',
    interval_maximum_price: 'Interval highest price',
    interval_tip: 'The highest price of the interval must be greater than the lowest price of the interval',
    grid_num: 'Number of grids',
    equal_difference: 'Equal difference',
    equal_ratio: 'Equal ratio',
    grid_tip: 'The estimated value of the single-grid rate of return within the price range, after deducting the handling fee.',
    grid_ratio_tip1: 'In order to ensure grid revenue, the profit rate of a single grid must be greater than 1%',
    grid_ratio_tip2: 'In order to ensure grid revenue, the profit rate of a single grid must be greater than 0%',
    input_amount_holder: 'input amount',
    input_amount_tip1: 'The input amount cannot be less than',
    input_amount_tip2: 'The input amount cannot exceed the available amount',
    Estimated_ratio: 'Estimated single grid profit rate',
    second_confirm: 'Second order confirmation',
    basic_settings: 'Basic settings',
    grid_mode: 'Grid mode',
    donot_prompt: 'Don\'t prompt again',
    cancel: 'Cancel',
    confirm: 'confirm',
    delete: 'delete',
    strategy: 'Strategy',
    del_grid_tip: 'After deleting the grid strategy, the robot will stop monitoring and running the strategy.',
    stop_martin_tip: 'After stopping the Martin strategy, the robot will stop monitoring and running the strategy.',
    strategy_cancel_order: 'Strategy and cancel order',
    strategy_no_cancellation: 'Strategy but no cancellation',
    strategy_cancel_order_radio1: 'The related pending orders of this strategy on the exchange will be removed, and losses may occur due to incomplete execution of the strategy',
    strategy_cancel_order_radio2: 'The strategy\'s related pending orders on the exchange will be cancelled',
    strategy_cancel_order_radio2_tip: 'It takes 20-60 minutes to cancel an order. To avoid incomplete order cancellation, please do not close the robot during this period',
    strategy_no_cancellation_radio1: 'Do not remove the relevant pending orders of the strategy on the exchange, the pending orders will continue to trade to the response price to generate profits',
    strategy_no_cancellation_radio2: 'Do not cancel the strategy\'s related pending orders on the exchange',
    quantity_transfinite: 'The quantity exceeds the limit',
    quantity_transfinite_auto0: 'Ordinary users can add up to 1 trading pair (including UB, BB), upgrade to VIP can add 3 trading pairs, upgrade to SVIP can add 8 trading pairs.',
    quantity_transfinite_auto1: 'VIP can add up to 3 trading pairs (including UB, BB), and upgrading to SVIP can add 8 trading pairs.',
    quantity_transfinite_auto2: 'SVIP can add up to 8 trading pairs (including UB, BB, and golden triangle arbitrage).',
    quantity_transfinite_auto3: 'YSVIP can add up to 12 trading pairs (including UB, BB, and golden triangle arbitrage), and it is not supported to add more than 12 trading pairs.',
    quantity_transfinite_semi0: 'Ordinary users can add up to 3 semi-automatic trading pairs, upgrading to VIP can add 8 trading pairs, upgrading to SVIP can add 12 trading pairs.',
    quantity_transfinite_semi1: 'VIP can add up to 8 semi-automatic trading pairs, and upgrading to SVIP can add 12 trading pairs.',
    quantity_transfinite_semi2: 'SVIP can add up to 12 semi-automatic trading pairs, beyond which it is not supported.',
    quantity_transfinite_semi3: 'YSVIP can add up to 20 semi-automatic trading pairs, beyond which it is not supported.',
    next_time: 'Next time',
    i_know: 'I know',
    open_svip: 'Open SVIP',
    sj_trade_tip: 'Golden triangle trade tip',
    sj_trade_content: 'Golden triangle arbitrage trading is only available to SVIP users, and you can enjoy 9 privileges when you open it.',
    open_svip_tip: 'SVIP can enjoy golden triangle arbitrage trading, BB trading and UB trading for a total of 8 trading pairs, contact customer service to open it',
    order_amount_must_be: 'The order amount must be',
    enter_content: 'Please enter the content',
    position_currency_position: 'Position of currency holding position',
    transaction_currency_position: 'Transaction currency position',
    is_deal_pair: 'Deal pair',
    enter: 'Please enter',
    is_trading_position: 'trading position',
    added_success: 'Added successfully',
    supported_transaction_currency: 'Supported transaction currency',
    supported_transaction_pairs: 'Supported transaction pairs',
    enter_the_bin_amount: 'Please enter the bin amount',
    position_amount_must_be: 'Position amount required',
    number_of_grids_cannot_be_less_than: 'The number of grids cannot be less than',
    number_of_grids_cannot_be_more_than: 'The number of grids cannot be more than',
    single_grid_profit_margin_must_be_greater_than: 'Single grid profit margin must be greater than',
    input_amount_and_available_amount: 'The input amount cannot be greater than the available amount',
    are_you_sure_del_sj: 'Are you sure you want to delete this golden triangle transaction?',
    // are_you_sure_del_bb: 'Are you sure you want to delete this BB transaction?',
    are_you_sure_del_bb: 'After deleting the BB transaction, the robot will stop monitoring and running the strategy and forcibly remove all pending orders.',
    // are_you_sure_del_ub: 'Are you sure you want to delete this UB transaction?',
    are_you_sure_del_ub: 'After deleting the UB strategy, the robot will stop monitoring and running the strategy. If you need to cancel the order, please go to the exchange for operation.',
    del_success: 'Delete successful',
    you_sure_you_suspend_strategy_martin: 'Are you sure you want to suspend this Martin strategy?',
    you_sure_you_suspend_strategy_grid: 'Are you sure you want to suspend this grid strategy?',
    policy_will_stop_completed: 'It will automatically stop after the policy is executed and the replenishment is stopped',
    policy_will_stop_clearance_sale: 'The policy will automatically stop after clearing and selling after the policy is executed',
    only_input: 'Only invest ',
    input_together: 'Invest ',
    <!-- Subcomponent end -->

    robot_ranking: 'Robot ranking',
    allow_other_users_to_view_my_policies: 'Allow other users to view my positions',
    billing_list_of_week: 'This week\'s billing list',
    // billing_list_of_week: 'This week\'s yield list',
    revenue_list_of_week: 'This week\'s revenue list',
    my_ranking: 'My ranking',
    not_listed: 'Not listed',
    see_policies: 'See TA positions',
    no_data: 'No data',
    all: 'all',
    sj:'Golden Triangle',
    bb: 'BB',
    ub: 'UB',
    grid: 'grid',
    martin: 'Martin',
    order: 'single',
    button_sure: 'Confirm',
    everyone_space: 'TA\'s position settings',
    cumulative_billing_volume_week: 'This week\'s cumulative billing volume',
    golden_triangle_arbitrage: 'Golden triangle arbitrage',
    bb_trade: 'Spot auto trade',
    ub_trade: 'UB trade',
    transaction_pair_added: 'Added transaction pair',
    init_space: 'Initial position',
    side: 'direction',
    trade_currency: 'trade currency',
    grid_strategy: 'Spot grid',
    martin_strategy: 'Spot martin',
    deal_pair: 'Deal pair',
    input_amount: 'input amount',
    input_coin: 'Buy currency',
    Price_range: 'price range',
    number_of_grids: 'Number of grids',
    policy_type: 'policy type',
    full_order_position: 'full order position',
    stop_ratio: 'Stop profit ratio',
    strategy_type_name0: 'Position Martin Strategy',
    strategy_type_name1: 'Profit Expected Martin Strategy',
    strategy_type_name2: 'Position & Profit Expectation Double Martin Strategy',
    // close_robot_sure: 'Are you sure to close the robot?',
    close_robot_sure: 'When the robot is turned off, the monitoring and operation of the strategy will be stopped',
    bind_binance_api_tip: 'Please go to the "Security Center" to bind Binance\'s API',
    bind_okx_api_tip: 'Please go to the "Security Center" to bind the API of okx',
    bind_api_tip: 'Please go to the "Security Center" to bind the exchange\'s API',

    button_start_stop_name1: 'Start Robot',
    button_start_stop_name2: 'Stop Robot',
    button_start_stop_name3: 'Removed',
    button_start_stop_name4: 'Service switching',
    button_start_stop_name5: 'Unbound API',

    <!-- Subpage starts -->
    policy_information: 'Policy information',
    policy_delegation: 'Policy delegation',
    initial_held_assets: 'Initial held assets',
    current_delegation: 'Current delegation',
    history_delegation: 'History delegation',
    history_transaction: 'History transaction',
    start_date: 'Start time',
    end_date: 'end time',
    complete_deal: 'Complete deal',
    price: 'price',
    num: 'Number',
    number_of_transactions: 'Number of transactions',
    entrusted_quantity: 'Entrusted quantity',
    date: 'date',
    time: 'time',
    average_transaction_price: 'Average transaction price',
    end_time_cannot_less_than_start: 'The end time cannot be less than the start time',
    start_time_cannot_creater_than_end: 'The start time cannot be greater than the end time',
    buy: 'buy',
    sell: 'sell',
    cancelled: 'Order cancelled',
    pending_order: 'Pending order',
    completed: 'completed',
    transaction_price: 'Transaction price',
    revenue_details: 'Revenue details',
    grid_hanging_list: 'Grid pending order',
    policy_info: 'Policy information',
    transaction_record: 'Transaction record',
    total_annualization: 'Total annualization',
    grid_profit: 'Grid Profit',
    grid_profit_tip: 'The sum of profits generated by each group of grids that have completed transactions',
    floating_profit_loss_tip: 'Except the grid profit, the floating profit and loss generated by holding the trading currency',
    investment_amount: 'Investment amount',
    investment_amount_tip: 'The sum of the investment currency when starting the strategy',
    currently_held_assets: 'Currently held assets',
    single_grid_amount: 'Single grid amount',
    expected_single_grid_profit_margin: 'Expected single grid profit margin',
    price_when_strategy_started: 'Price when strategy is started',
    number_of_pending_orders: 'Number of pending order transactions',
    latest_market_price: 'The latest market price',
    pay_the_bill: 'pay the bill',
    selling_order: 'Sell order',
    number: 'number',
    entrusted_price: 'Entrusted price',
    trading_up_and_down: 'The transaction will be traded after up or down',
    data_acquisition_in_progress: 'Data acquisition',
    transaction_details: 'Transaction details',
    buy_in: 'Buy in',
    sell_out: 'sell out',
    entrusted_time: 'Entrusted time',
    transaction_time: 'Transaction time',
    transaction_volume: 'Transaction volume',
    service_charge: 'handling fee',
    del_grid_strategy: 'Delete grid strategy',
    equal_difference_value: 'Equal difference value',
    total_arbitrage_times_grid: 'Total Arbitrage Times of Grid',
    negative_tip: 'The grid profit minus the handling fee is negative, it is recommended to reset the grid strategy.',
    operation: 'operation',
    trading_direction: 'trading direction',
    transaction_fee: 'Transaction fee',
    shortcut_settings: 'Shortcut settings',
    cyclic_recording: 'Cyclic recording',
    transaction_settings: 'Transaction settings',
    stop_replenishment: 'Stop replenishment',
    start_replenishment: 'Open replenishment',
    clearance_sale: 'Clearance sell',
    one_click_replenishment: 'One click replenishment',
    full_stop: 'full stop loss',
    tracking_interference_stop_callback: 'Tracking stop profit callback',
    tracking_replenishment_callback: 'Tracking replenishment callback',
    secondary_cycle: 'Secondary cycle',
    in_progress: 'in progress',
    interference_stop_completed: 'stop profit completed',
    stop_loss_complete: 'Stop Loss Complete',
    completion_last_transaction_time: 'Completion/last transaction time',
    income_amount: 'income amount',
    stop_martin_strategy: 'Stop Martin Strategy',
    stop_policy_and_cancel_order: 'Stop the policy and cancel the order',
    stop_strategy_no_cancellation: 'Stop the policy and cancel the order',
    stop_replenishment_tip: 'After the replenishment is stopped, the price will no longer be added to the replenishment position',
    stop_replenishment_radio1: 'Only this cycle stops replenishment',
    stop_replenishment_radio1_tip: 'No more positions will be added in this cycle, and it will not affect the operation of the next cycle',
    stop_replenishment_radio2: 'This cycle stops replenishing orders and stops the strategy after completion',
    stop_replenishment_radio2_tip: 'This cycle will no longer add positions and the stop strategy will not be cycled after completion',
    clearance_sale_tip: 'All current positions will be sold at market price pending orders',
    clearance_sale_radio1: 'Only this cycle is cleared and sold',
    clearance_sale_radio1_tip: 'The current position is cleared and sold, it will not affect the operation of the next cycle',
    clearance_sale_radio2: 'This cycle is cleared to sell and stop the strategy',
    clearance_sale_radio2_tip: 'The current positions are all sold and the stop strategy is no longer looping',
    one_click_replenishment_tip: 'This replenishment only applies to the current cycle and does not affect the strategy\'s replenishment configuration. There may be a certain delay because the order to close the position will be executed after the process of trailing the profit and the trailing position is completed.',
    replenishment_position: 'Replenishment position',
    replenishment_position_tip1: 'The replenishment amount cannot be less than the minimum amount',
    replenishment_position_tip2: 'The replenishment amount cannot be greater than the available balance',
    replenishment_position_price: 'Replenishment price',
    stop_supplement_isrunning: 'The loop is running, please try again later!',
    open_supplement_open_tip: 'After opening the supplementary order, the price will continue to buy when the price reaches the supplementary order position, and the next cycle will be carried out according to the settings',
    supplement_opened: 'The supplementary order has been opened',
    operation_success: 'The operation succeeded',
    set_success: 'Set successfully',
    input_replenishment_position: 'Please enter the replenishment amount',
    status_modified_success: 'Status modified successfully',
    replenishment_amount_required: 'The replenishment amount is required',
    total_profit: 'Total profit',
    done: 'done',
    trend_cycle: 'Trend cycle',
    minutes: 'minutes',
    first_order_limit: 'First order limit',
    replenishment_times: 'Number of replenishment',
    times_of_cycles: 'Number of cycles',
    stop_loss_ratio: 'Stop Loss Ratio',
    infinite: 'infinite',
    deal_record: 'Deal record',
    see: 'View',
    replenishment: 'Fill Position',
    interference: 'Take Profit',
    loss: 'Stop Loss',
    <!-- end of subpage -->

    <!-- new -->
    are_you_sure_del_grid: 'Are you sure you want to delete this grid transaction?',
    are_you_sure_del_martin: 'Are you sure you want to stop this Martin transaction?',
    save: 'Save',
    limitless: 'limitless',
    open: 'on',
    close: 'off',
    sj_error_tip: 'You currently have other automatic strategies being initialized, you can "save" this strategy and manually start it later',
    position_set_tip: 'It is recommended to set positions not to exceed',
    transaction_tips: 'This transaction is in ',
    cancel_reset: 'cancel',
    cancel_edit: 'cancel',
    start_: 'set',
    restart_: 'reSet',
    save_changes: 'save',
    add_martin: 'Set Martin Strategy',
    reset_martin: 'Reset Martin Strategy',
    set_trade: 'Transaction Setup',
    save_and_start: 'Start',
    no_more_than_available_balance: 'It is recommended not to exceed the available balance',
    batch_open: 'Batch opening',
    select_all: 'Select all',
    del_grid_tip_stop: 'The deletion strategy of the closed state of the robot will not cancel the order. If it is necessary to cancel the order, go to the exchange for manual cancellation',
    stop_martin_tip_stop: 'Since the robot is closed, the pending order cannot be cancelled, and the order must be manually cancelled at the exchange.',
    please_fill_in_any_position: 'Fill in at least one position',
    save_success: 'Successfully saved',
    go_to_strategy_list_enable: 'You need to enable policies in batches in the list!',
    set_up_multiple_groups_golden: 'If you set up multiple groups of golden triangles containing the same currency, it is recommended to go to the list and start the strategy in batches at the same time to ensure maximum revenue！',
    fee_relief_eight: '20% discount on fuel fee',
    state: 'state',
    initializing: 'Initializing',
    go_to_exchange_recharge: 'Please go to the exchange to recharge',
    purchase_price: 'Purchase price',
    delete_sj_for_closed_bot: 'Since the robot is closed, the pending order cannot be cancelled, and the order must be manually cancelled at the exchange.',
    delete_sj_for_started_bot: 'After deleting the golden triangle transaction, the robot will stop monitoring and running the strategy and forcefully cancel all pending orders.',
    min_position: 'It is recommended to set the bin not less than',
    contract_strategy_tip: 'Long and short hedging shock arbitrage, risk controllable',
    grid_contract_strategy: 'Contract AI trading',
    input_grid_contract_amount: 'Set USDT amount of investment strategy',
    futures_currently_held: 'Futures currently held',
    trading_place: 'Exchange',
    long_order: 'Long',
    short_order: 'Short',
    cont: 'Cont',
    earnings_change: 'Earnings change',
    pause_contract_tip: 'After the Supreme Hedging transaction is suspended, the robot will stop monitoring and running the strategy.',
    del_contract_tip: 'After deleting the supreme hedging transaction, the robot will stop monitoring and running the strategy.',
    pause_risk_tip: 'I am aware of the risks and take them voluntarily.',
    liquidation_risk_tip: 'There is a risk of liquidation, and you need to go to the exchange to manually cancel the order and close the position.',
    select_risk_tip: 'Please choose to take the risk',
    are_you_cancel_order: 'cancel all orders',
    cancel_contract_all_no_deal: 'All open orders for the current contract will be automatically cancelled',
    retain_contract_all_no_deal: 'Outstanding orders will be retained in your contract account',
    are_you_close_position: 'Close all positions',
    yes: 'Yes',
    no: 'No',
    closed_market_price_all: 'All positions in the current contract will be closed at the market price',
    retain_market_price_all: 'Open positions will be retained in your contract account',
    del_hedge: 'After deleting the hedging contract transaction, the robot will stop monitoring and running the strategy, but will not remove the pending orders and open positions. If necessary, please go to the exchange for manual processing.',
    add_position: 'Edit',
    add_contract_tip: '1000≤仓位>6000，币种:DOT，杠杆：10x\n' +
        '6000≤仓位>20000，币种:BTC，杠杆：20x\n' +
        '仓位≥20000，币种:BTC，杠杆：10x\n',
    margin_rate: 'Margin rate',
    add_position_long: 'Edit position',
    more_initial_amount: 'The entered bin cannot be less than the initial amount.',
    supreme_hedge: 'Hedging contract',
    add_position_success: 'Position edited successfully',
    business_line: 'Business line',
    perpetual: 'Perpetual',
    position_type: 'type',
    cross: 'Cross',
    order_Value: 'Order Value',
    unfilled: 'Unfilled',
    old: 'Old',
    trading_position: 'trading position',
    positions: 'Positions',
    position_history: 'Position history',
    long: 'long',
    short: 'short',
    total_position: 'Total',
    avg_price: 'Avg price',
    IMR: 'IMR',
    mark_price: 'Mark price',
    liq_price: 'Liq. price',
    pl: 'PL',
    opened: 'Opened',
    avg_cost: 'Avg. cost',
    avg_close_price: 'Avg. close price',
    max_open_int: 'Max open int.',
    closed_position: 'Closed',
    closed_time: 'Closed',
    partial_close: 'Partial close',
    close_all: 'Close all',
    stop_profit_stop_loss: 'Stop',
    limit_market: 'Limit | Market',
    billing_quantity: 'Billing quantity',
    position_limit_description: 'The position limit is calculated according to the minimum trading volume limit of the exchange and the robot design.',
    select_hedge_coin: 'select coin',
    position_floating: 'Position Floating',
    profit_withdrawn: 'Profit Withdrawn',
    available_balance_hedge: 'It is recommended to set no more than the available balance',
    test_is_full_tip: 'The quota of this period\'s internal test is full. The next period\'s internal test will open in June. Please look forward to it.',
    risk_setting: 'Risk Setting',
    hedge_stop_loss_tip: 'Whether to stop loss when the position is insufficient',
    hedge_stop_loss_tip_two: ' (based on the price stop loss of the last position increase)',
    hedge_stop_surplus_tip: 'Maximum reach profit (the strategy will stop after reaching)',
    not_stop_loss: 'No Stop',
    not_stop_surplus: 'No Stop',
    hedge_started_tip: 'when adding a hedging contract transaction, you need to close the robot before creating a policy',
    hedge_create_dialog_tip: 'fuel arrearage will cause the robot to shut down and stop monitoring the strategy, which will pose a great risk to the contract strategy. In order to avoid this situation, the fuel cost is limited to be greater than 10% of the principal when the strategy is opened. For example, if the strategic position is 900usdt, the fuel cost must be greater than or equal to 90usdt. There will be SMS / email or customer service reminder when the fuel cost is insufficient; Users can enjoy the credit line of 10% of the latest fuel charge recharge; Fuel costs can be transferred and withdrawn at any time.',
    input_multiple: 'Please enter a multiple of 300',
    enter_trade: 'Enter the trade',
    no_trade_leave: 'Don\'t trade for now, leave',
    enter_trade_tip: 'Please read carefully and confirm to check the "UPlus Hedging Contract Trading Agreement"',
    hedging_contract_trading_risk_title: 'UPlus Hedging Contract Trading Risk Warning',
    hedge_detail_tip1: 'Only plan to transfer profit to trading account',
    hedge_detail_tip2: 'Strategy trading generates profit for one trade',
    hedge_detail_tip3: 'Yield=Total Return/Investment Amount',
    hedge_detail_tip4: 'Floating profit and loss of open positions held',
    hedge_detail_tip5: 'Position funds set by the strategy',
    hedge_detail_tip6: 'Amount of USDT in exchange-traded accounts',
    exchange_assets: 'Exchange assets',
    funds_cannot_more_balance: 'Set funds cannot exceed available balance',
    cannot_more_balance: 'Cannot exceed available balance',
    funds_set_tip: 'To ensure efficient use of funds, it is recommended to set a multiple of 300',
    warn1: 'Trading with smart contracts is complex and risky. You may realize substantial gains or losses when trading contracts, so be fully prepared before you start trading. Small price fluctuations can also have a big impact on your trades.',
    warn2: 'When the gas fee of the UPlus account is lower than -10U, the smart contract transaction will be automatically closed, which will result in higher risk.',
    warn3: 'Please be sure to read the \"UPlus Smart furures Trading Agreement\" carefully before you start trading.',
    warn4: 'I have read and understood the relevant agreement and am aware of the risks',
    Click_view: ' Click to view',
    UPlus_Smart_furures_Trading_Agreement: '\"UPlus Smart furures Trading Agreement\"',
    stop_loss: 'Stop loss',
    please_select_currency: 'Please select transaction currency',
    loss_warn_tip1: 'When the risk of the strategy is high, some positions will be closed to release the margin to defend against risks. The floating losses of closed positions will be turned into real losses. Subsequent profits will give priority to making up losses, and making up losses will not consume fuel.',
    loss_warn_tip2: 'If you want to carry the order to the market callback, you can choose not to lose. The strategy uses the cross margin, which may lose the margin other than the trading account position setting, so you need to pay attention to the risk.',
    hedge_balance_tip_b: 'Binance contract account funds - other strategies occupy funds',
    hedge_balance_tip_o: 'Okx trading account funds - other strategies occupy funds',
    Abnormal_Reminder: 'Abnormal Reminder',
    warn_dialog_title: "The robot runs abnormally, please fix it in time",
    Recharge: "Recharge",
    fill_up_positions: "Fill up positions",
    input_fillup_position: "Please enter fillup amount",
    amount: "Amount",
    input_position: "Invest",
    available_fill_position: "Available funds",
    make_up_loss_ratio: "Make up loss ratio",
    risk_level: "risk level",
    spot_limit_tip: "Your spot automatic trading has been added to the upper limit, please delete it and then continue to add!",
    futures_limit_tip: "The upper limit has been added to your contract transaction, please delete it and add it again!",
    semi_limit_tip: "Your spot semi-automatic trading limit has been added, please delete it and add it again!",
    minimum_position: "Minimum position",
    input_min_position: "minimum_position",
    futures_products: "Futures products",
    Spot_product: "Spot product",
    futures_spot: "Futures spot",
    futures_spot_strategy: 'Futures Spot AI',
    orders: 'Orders',
    futures_tip1_3: "Insensitive to risk, pursuing high returns, and can tolerate more principal losses",
    no_stop_loss: "손절매",
}
