export const roundTwoFilter = value => {
    // if (value=='' || value==null) return 0
    let newValue
    if (value==undefined) newValue=0+''
    else  newValue=value+''
    if (newValue.indexOf('.')==-1) return newValue+'.00'
    if (newValue.slice(newValue.indexOf('.')+1).length==1) return newValue+'0'
    return newValue.substring(0,newValue.indexOf('.')+3)
    // return Math.floor(value*100)/100
}
export const roundEightFilter = value => {
    let newValue
    if (value==undefined) newValue=0+''
    else newValue=value+''
    newValue = newValue.replace(/(?:\.0*|(\.\d+?)0+)$/, '$1');
    if (newValue.indexOf('.'+1))
    if (newValue.indexOf('.')==-1) return newValue+'.00'
    if (newValue.slice(newValue.indexOf('.')+1).length==1) return newValue+'0'
    // return newValue.substring(0,newValue.length)
    if (newValue.slice(newValue.indexOf('.') + 1).length > 8) {
        return jiequ(newValue)
        // return newValue.substring(0, newValue.indexOf('.') + 9).replace(/(?:\.0*|(\.\d+?)0+)$/, '$1');
    }else{
        return newValue.substring(0,newValue.length)
    }
}

function jiequ(newValue){
    for (let i=9; i<(newValue.slice(newValue.indexOf('.')).length) + 1; i++){
        if (parseFloat(newValue.substring(0, newValue.indexOf('.') + i)) > 0 ){
            return newValue.substring(0, newValue.indexOf('.') + i).replace(/(?:\.0*|(\.\d+?)0+)$/, '$1')
        }
    }
}

// 千分位
export const thousandth = value => {
    let str = value.toString().replace(/,/g,"");
    let integer = str.split(".")[0];  //获取整数位
    let decimal = str.split(".")[1];  //获取小数
    let res = [];
    if(integer.length > 3){
        let counter = 0;
        for (let i = integer.length - 1; i >= 0; i--) {
            counter++;
            res.unshift(integer[i]);
            if (!(counter % 3) && i != 0) {
                res.unshift(',');
            }
        }
        if(decimal){
            res = res.join("") + '.' + decimal;
        }else{
            res = res.join("");
        }
    }else{
        res = str;
    }
    return res;
}
