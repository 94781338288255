import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
/**
 * 引入echarts插件
 */
import * as echarts from 'echarts'
Vue.prototype.$echarts=echarts
/**
 * 按需导入element ui
 */
import './plugin/element'
import '../theme/index.css'
/**
 * 引入i18n
 */
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import html2canvas from "html2canvas";
Vue.prototype.$html2canvas=html2canvas
// import('default-passive-events')

/**
 * 引入過濾器
 */
import  * as filters from "./utils/filter_utils";
Object.keys(filters).forEach(key=>{
  Vue.filter(key,filters[key])
})

/**
 * 引入拖拽功能
 */
import draggable from 'vuedraggable';
Vue.use(draggable);
import './assets/js/dialogdrag'

const i18n = new VueI18n({
  locale: 'zh',    // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'zh': require('./plugin/lang/zh'),   // 中文语言包
    'en': require('./plugin/lang/en'),    // 英文语言包
    'zh-tw': require('./plugin/lang/zh-tw')    // 英文语言包
  }
})


import animated from 'animate.css';
Vue.use(animated)

var _hmt = _hmt || [];
window._hmt = _hmt;
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?2bd6ff5781b7100e177cc961e4124406";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();


const vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

import util from './utils/util'
Vue.prototype.$util = util

import registered_address from "./utils/registered_address"
Vue.prototype.$registered_address = registered_address

export default vue

if (location.pathname == '/register' && location.href.indexOf('/register?invitation=') > -1){
  localStorage.setItem('language', '简体中文')
}

if (localStorage.getItem('language')) {
  localStorage.getItem('language') === '繁体中文' ? vue.$i18n.locale = 'zh-tw' : localStorage.getItem('language') === '简体中文' ? vue.$i18n.locale = 'zh' : vue.$i18n.locale = 'en'
}


Vue.prototype.resetSetItem = (key, newVal) => {
  // if (key === 'updateTime') {
    // 创建一个StorageEvent事件
    let newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: (k, val) => {
        localStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent);
      }
    };
    return storage.setItem(key, newVal);
  // }
};

// 引入element ui自带语言包
import ElementUILocale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import zhTwLocale from 'element-ui/lib/locale/lang/zh-TW'

const DEFAULT_LANG = '繁体中文'
const LOCALE_KEY = 'language'

const locales = {
  'zh': require('./plugin/lang/zh'),   // 中文语言包
  'en': require('./plugin/lang/en'),    // 英文语言包
  'zh-tw': require('./plugin/lang/zh-tw')    // 英文语言包
}

const UIlocales = {
  zh: zhLocale,
  en: enLocale,
  tw: zhTwLocale,
}

// 通过判断lang语言标志符来return先对应的语言
const setUIlocales = lang =>{
  switch (lang) {
    case 'zh': return UIlocales.zh
    case 'en': return UIlocales.en
    case 'zh-tw': return UIlocales.tw
  }
}

export const setup = lang => {
  if(lang == undefined){
    // lang = window.localStorage.getItem(LOCALE_KEY)
    switch (window.localStorage.getItem(LOCALE_KEY)){
      case 'English':
        lang = 'en'
        break;
      case '简体中文':
        lang = 'zh'
        break;
      case '繁体中文':
        lang = 'zh-tw'
        break;
    }
    if ( locales[lang] == undefined ) {
      lang = DEFAULT_LANG
    }
  }
  Object.keys(locales).forEach(item => {
    document.body.classList.remove('lang-${item}')
  })
  document.body.classList.add('lang-${lang}')
  document.body.setAttribute('lang', lang)

  Vue.config.lang = lang
  i18n.locale = lang
  ElementUILocale.use(setUIlocales(lang)) // element ui 切换语言
}

setup()
