module.exports={
    title:'合約量化機器人，安全便捷高效讓交易更安心。',
    ul_li1:'聯係客服',
    ul_li2:'幫助中心',
    ul_li2_phone:'幫助中心',
    ul_li3:'OKX注冊地址',
    ul_li4:'服務條款',
    ul_li4_phone:'服務條款',
    download_app: '下載App',
    send: '發送'
}
