module.exports = {
    my_property:'我的資產',
    property_total:'資產總折合',
    capital_account:'資金賬戶',
    withdraw:'提現',
    withdraw1:'提現中',
    transfer:'劃轉',
    trading_account:'燃料賬戶',
    trading_account_card: '燃料/点卡账户',
    top_up:'充值',
    // rebate_account:'返傭獎金',
    rebate_account:'點卡',
    top_up_order:'充值訂單',
    withdraw_order:'提現訂單',
    time:'時間',
    type:'類型',
    account:'賬戶',
    kind:'幣種',
    quantity:'數量',
    address:'地址',
    schedule:'進度',
    state:'狀態',
    top_up_success:'充值成功',
    withdraw_success:'提現成功',
    underway:'進行中',
    repeal_withdraw:'撤銷提現',
    record:'記錄',
    record_time:'記錄時間',
    account_type:'賬單類型',
    transaction:'交易類型',
    fee:'扣費',
    property_change:'資產變更',
    account_balance:'賬戶餘額',
    user_fee:'燃料扣費',
    card_fee:'點卡扣费',
    award_money:'花費金額',
    billing_profit:'開單利潤',
    change_address:'變更地址/項目',
    tip:'仅显示7天内的充值/提现订单记录',
    my_capital:'我的資金',
    capital_ratio:'資金比例',
    invite_code:'邀請碼',
    content: '内容',
    combo: '套餐',
    deduct: '扣除'
}
