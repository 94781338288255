module.exports = {
    my_property:'My property',
    property_total:'Total asset conversion',
    capital_account:'capital account',
    withdraw:'withdrawal',
    withdraw1:'withdrawing',
    transfer:'transfer',
    trading_account:'fuel account',
    trading_account_card: 'fuel/point card account',
    top_up:'Recharge',
    // rebate_account:'Rebate bonus',
    rebate_account:'card',
    top_up_order:'Top up order',
    withdraw_order:'Withdrawal order',
    time:'time',
    type:'type',
    account:'account',
    kind:'currency',
    quantity:'quantity',
    address:'Address',
    schedule:'schedule',
    state:'state',
    top_up_success:'recharge successful',
    withdraw_success:'withdrawal successful',
    underway:'in progress',
    repeal_withdraw:'Cancel withdrawal',
    record:'record',
    record_time:'record time',
    account_type:'Bill Type',
    transaction:'transaction type',
    fee:'deduction',
    property_change:'Asset change',
    account_balance:'account balance',
    user_fee:'Fuel deduction',
    card_fee:'point card deduction',
    award_money:'amount',
    billing_profit:'Billing profit',
    change_address:'Change address/item',
    tip:'Only display the deposit/withdrawal order records within 7 days',
    my_capital:'My capital',
    capital_ratio:'capital ratio',
    invite_code:'invitation code',
    content: 'Content',
    combo: 'Combo',
    deduct: 'deduct'
}
