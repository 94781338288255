const robot = require("./language/en/robot")
const user_center = require("./language/en/user_center");
const rebate = require("./language/en/rabate");
const member_right = require("./language/en/grant_member/member_right");
const member = require("./language/en/grant_member/member");
const martinComments = require("./language/en/martin/martinComments");
const detail_param = require("./language/en/martin/detail_param");
const transfer = require("./language/en/user/transfer");
const top_up = require("./language/en/top_up");
const property = require("./language/en/user/property");
const personal = require("./language/en/user/personal");
const center = require("./language/en/center");
const validate = require("./language/en/validate");
const help = require("./language/en/help");
const login = require("./language/en/login");
const footer = require("./language/en/footer");
const alter = require("./language/en/alter")
const home = require("./language/en/home")
const navbar = require("./language/en/navbar")
const problem = require("./language/en/problem")
module.exports = {
    message: {
        login: 'Login',
        Username: 'Username',
        Password: 'Password',
        Captcha: 'Captcha',
        Language: 'Language',
        en: 'Chinese',
        en: 'English',
        footer_title: 'It is committed to creating a safe and convenient strategic trading platform, bringing simple, extreme and efficient financial investment experience.  '
    },
    robot,

    user_center,
    rebate,
    member_right,
    member,
    martinComments,
    detail_param,
    transfer,
    top_up,
    property,
    personal,
    center,
    validate,
    help,
    login,
    footer,
    alter,
    home,
    navbar,
    problem
}

